import { Button, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  FeatureList,
  FeatureListItem,
  ExclusiveSectionWrapper,
  ExclusiveSectionImage,
  Summary,
} from 'components/ExclusiveSection/styled';
import { SectionLayout } from 'components/layouts/sectionLayout';
import { CTA_REDIRECT_URL } from 'constant';
import messages from './messages';
import exclusiveImage from './assets/exclusive.jpg';

function ExclusiveSection() {
  const intl = useIntl();

  return (
    <SectionLayout>
      <ExclusiveSectionWrapper>
        <Summary>
          <Typography variant="h1">
            <FormattedMessage {...messages.title} />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage {...messages.description} />
          </Typography>
          <FeatureList>
            <FeatureListItem>
              <FormattedMessage {...messages.serenity} />
            </FeatureListItem>
            <FeatureListItem>
              <FormattedMessage {...messages.harmony} />
            </FeatureListItem>
            <FeatureListItem>
              <FormattedMessage {...messages.velocity} />
            </FeatureListItem>
          </FeatureList>
          <Button variant="contained" disableElevation href={CTA_REDIRECT_URL}>
            <FormattedMessage {...messages.button} />
          </Button>
        </Summary>
        <ExclusiveSectionImage>
          <img
            src={exclusiveImage}
            alt={intl.formatMessage({ ...messages.imageAltText })}
          />
        </ExclusiveSectionImage>
      </ExclusiveSectionWrapper>
    </SectionLayout>
  );
}

export default ExclusiveSection;
