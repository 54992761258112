import React from 'react';
import { Button, Typography, Box, Container, styled } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { ReactComponent as Logo2 } from 'assets/icons/logo2.svg';
import API from 'API';
import PasswordTextField from '../../../components/PasswordTextField/PasswordTextField';
import PasswordRequirements from '../../../components/PasswordRequirments/PasswordRequirements';
import { ReactComponent as Image } from '../assets/fg-image.svg';

const MuiContainer = styled(Container)`
  text-align: center;
  padding-top: 36px;
  max-width: 475px !important;
`;

type FormType = {
  password: string;
  confirmPassword: string;
};

function PasswordRecoveryForm() {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<FormType>();

  const onSubmit: SubmitHandler<FormType> = (data) => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    if (code) {
      API.User.setPass(data.password, code).then((res) => {
        if (res?.data?.message) {
          navigate('/forgot-password/success');
        }
      });
    }
  };

  return (
    <>
      <Helmet>
        <style>
          {`
              body {
                  background-color: rgba(109,109,109,0.04);
              }
           `}
        </style>
      </Helmet>
      <MuiContainer>
        <Link to="/">
          <Logo2 />
        </Link>
        <Box mt={16} mb={3}>
          <Image />
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h6">Create a New Password</Typography>
          <Typography variant="body2" color="#000000" mt={2} mb={2}>
            Please enter a new password below
          </Typography>
          <PasswordTextField
            autoComplete="new-password"
            fullWidth
            label="New Password"
            margin="normal"
            {...register('password', {
              required: 'Please enter a password',
              minLength: {
                value: 8,
                message: 'Please enter a password with at least 8 characters',
              },
              pattern: {
                value:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                message:
                  'Please follow the instructions of password requirements',
              },
            })}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
          />
          <PasswordRequirements />
          <PasswordTextField
            autoComplete="new-password"
            fullWidth
            label="Confirm Password"
            margin="normal"
            {...register('confirmPassword', {
              required: 'Password Confirmation is required',
              validate: (value) =>
                value === getValues('password')
                  ? true
                  : 'passwords should match',
            })}
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword?.message}
          />
          <Button
            variant="contained"
            type="submit"
            size="large"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            Continue
          </Button>
        </form>
      </MuiContainer>
    </>
  );
}

export default PasswordRecoveryForm;
