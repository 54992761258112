import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, TextField, Typography } from '@mui/material';
import { ReactComponent as Logo2 } from 'assets/icons/logo2.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { AppState } from 'store/app/AppState';
import HouseImage from './assets/house.png';
import { ButtonWrapper, LinkPrimary } from './styles';
import API from '../../API';
import PasswordTextField from '../../components/PasswordTextField/PasswordTextField';

type FormInputType = {
  username: string;
  password: string;
};

function SignIn() {
  const navigate = useNavigate();
  const { setAccessToken } = AppState.useContainer();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormInputType>();

  const onSubmit: SubmitHandler<FormInputType> = async ({
    username,
    password,
  }) => {
    await API.User.login(username, password).then((data) => {
      if (data) {
        setAccessToken(data.data);
        // .onboardingCompleted
        if (data.data.moneyUser) {
          navigate('/');
        } else {
          navigate('/onboard');
        }
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <style>
          {`
              body {
                  overflow: hidden;
                  background-color: rgba(109,109,109,0.04);
              }
           `}
        </style>
      </Helmet>

      <Box display="flex">
        <Box m="228px 160px 228px 256px">
          <Box mr={6}>
            <Logo2 />
          </Box>

          <Typography variant="h6" mt={4}>
            Login to Richr Money
          </Typography>

          <TextField
            autoComplete="username"
            label="Username"
            fullWidth
            margin="normal"
            {...register('username', {
              required: 'Please enter a password',
            })}
            error={Boolean(errors.username)}
            helperText={errors.username?.message}
          />

          <Box textAlign="right">
            <LinkPrimary to="/forgot-password">
              Forgot your password?
            </LinkPrimary>
          </Box>

          <PasswordTextField
            autoComplete="current-password"
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            {...register('password', {
              required: 'Please enter a password',
            })}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
          />

          <ButtonWrapper>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              startIcon={<KeyIcon />}
            >
              SIGN IN TO YOUR ACCOUNT
            </Button>
          </ButtonWrapper>
        </Box>
        <img src={HouseImage} alt="house" width="100%" height="100%" />
      </Box>
    </form>
  );
}

export default SignIn;
