import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PaperLayout from 'components/PaperLayout/PaperLayout';
import { OnboardState } from 'store/OnboardState/OnboardState';
import { ONBOARD_STATE } from 'constant';
import { useNavigate } from 'react-router-dom';
import { isComponentApplyStepStyling } from 'utils/hooks/onboarding';

function Onboard() {
  const navigate = useNavigate();

  useEffect(() => {
    const data = localStorage.getItem(ONBOARD_STATE);
    if (data) {
      switch (JSON.parse(data || '{}').step) {
        case 1:
          navigate('/onboard/financial-situation');
          break;
        case 2:
          navigate('/onboard/essentials');
          break;
        case 3:
          navigate('/onboard/employment-info');
          break;
        case 4:
          navigate('/onboard/employment-info/2');
          break;
        case 5:
          navigate('/onboard/risk-profile/1');
          break;
        case 6:
          navigate('/onboard/risk-profile/2');
          break;
        case 7:
          navigate('/onboard/risk-profile/3');
          break;
        case 8:
          navigate('/onboard/agree-terms');
          break;
        case 9:
          navigate('/onboard/recommended-investments');
          break;
        case 10:
          navigate('/onboard/investment-goals');
          break;
        case 11:
          navigate('/onboard/confirm-investments');
          break;
        default:
          navigate('/onboard/info');
      }
    } else if (
      window.location.pathname === '/onboard/' ||
      window.location.pathname === '/onboard'
    ) {
      navigate('/onboard/info');
    }
  }, [navigate]);

  return (
    <OnboardState.Provider>
      <Helmet>
        <style>
          {isComponentApplyStepStyling()
            ? `
              body {
                  background-color: #0082BC;
              }
           `
            : ''}
        </style>
      </Helmet>
      <PaperLayout />
    </OnboardState.Provider>
  );
}

export default Onboard;
