export const breakpoints = {
  xs: 0,
  sm: '600px',
  md: '900px',
  lg: '1200px',
  xl: '1536px',
};

export const palette = {
  primary: {
    main: '#00D4A1',
  },
  secondary: {
    main: '#555e67',
    light: '#323941',
    dark: '#7f8589',
  },
  success: {
    main: '#004C56',
    light: '#f5fafe',
  },
  error: {
    main: '#D40000',
  },
  text: {
    primary: '#00152A',
    secondary: '#485651',
  },
  background: {
    default: '#e6e8ee',
    paper: '#fafbfc',
  },
  common: {
    white: '#fff',
    black: '#1b2126',
  },
};
