import Axios from '../axios';

export const User = {
  login: (username: string, password: string) =>
    Axios.post('/money-api/auth/login', {
      identifier: username,
      password,
    }).then((res) => res),
  reset: (username: string) =>
    Axios.post('/money-api/auth/password-reset', {
      username,
    }).then((res) => res),
  setPass: (password: string, code: string) =>
    Axios.post('/money-api/auth/password-reset-confirm', {
      password,
      code,
    }).then((res) => res),
  signUp: (data: any) =>
    Axios.post('/money-api/auth/sign-up', data).then((res) => res),
  userDetails: (data: any) =>
    Axios.post('/money-api/auth/user-details', data).then((res) => res),

  getUserInfo: () => Axios.get('/money-api/user-info').then((res) => res),
  checkInviteCode: (code: any) =>
    Axios.post('/money-api/auth/check-invite-code', code).then((res) => res),
  checkUserNameUnique: (username: any) =>
    Axios.post('/money-api/auth/check-username-unique', username).then(
      (res) => res
    ),
  sendVerificationCode: (data: any) =>
    Axios.post('/money-api/auth/send-verification-code', data).then(
      (res) => res
    ),
  sendOnboardComplete: () =>
    Axios.post('/money-api/auth/onboarding-complete').then((res) => res),
  getZohoDocumentStatus: () =>
    Axios.get('/money-api/zoho-sign/get-document-details').then((res) => res),
  getAccount: () =>
    Axios.get('/money-api/drivewealth/get-account').then((res: any) => res),
  getJobIndustries: () =>
    Axios.get('/money-api/auth/jobs/industries').then((res: any) => res),
  getMonthlyStatements: () =>
    Axios.get('/money-api/drivewealth/get-monthly-statements').then(
      (res) => res
    ),
  getTradeConfirmations: () =>
    Axios.get('/money-api/drivewealth/get-trade-confirmations').then(
      (res) => res
    ),
  getTaxDocuments: () =>
    Axios.get('/money-api/drivewealth/tax-documents').then((res) => res),
  getTransactions: () =>
    Axios.get('/money-api/drivewealth/account-transactions').then((res) => res),
  getAccountSummary: () =>
    Axios.get('/money-api/drivewealth/get-account-summary').then((res) => res),
  getStatementFile: (fileKey: any) =>
    Axios.get(`/money-api/drivewealth/get-statement-file/${fileKey}`).then(
      (res) => res
    ),
};
