import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  Zoom,
} from "@mui/material";
import SignedInHeader from "components/Header/SignedInHeader";
import Footer from "components/Footer/Footer";
import { AppState } from "store/app/AppState";
import { usd } from "utils/helpers/currencyFormatter";
import InfoIcon from "@mui/icons-material/Info";
import API from "API";
import styled from "styled-components";

function Dashboard() {
  const { appState } = AppState.useContainer();
  const [currentAmount, setCurrentAmount] = useState<number>(0);

  useEffect(() => {
    API.User.getAccountSummary().then((res) => {
      setCurrentAmount(
        (res.data.accountSummary?.cash?.cashBalance || 0) +
          (res.data.accountSummary?.equity?.equityValue || 0),
      );
    });
  }, []);

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#000",
    },
  }));

  return (
    <>
      <Box bgcolor="rgba(109,109,109,0.04)">
        <Container maxWidth="xl">
          <SignedInHeader />
        </Container>
      </Box>
      <Container maxWidth="xl" sx={{ mt: 7, mb: 1 }}>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Box display="flex">
            <Typography
              variant="h4"
              color="#000"
              fontFamily="Walsheim-Regular, sans-serif"
              fontWeight="bold"
              mr={2}
            >
              {appState.user?.userName}
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderWidth: 1.5 }}
            />
            {appState.user.onboardingCompleted &&
              appState.accountData.accountNo && (
                <Box display="flex" flexDirection="row">
                  <Typography
                    variant="h4"
                    color="#5A5A5A"
                    ml={2}
                    mr={1}
                    fontWeight="bold"
                  >
                    {appState.accountData.accountNo}
                  </Typography>
                  <Chip
                    label="ACCOUNT"
                    sx={{ backgroundColor: "#000", color: "#fff" }}
                  />
                </Box>
              )}
          </Box>
        </Grid>
        <Divider>
          <Chip
            label="DETAILS"
            sx={{ backgroundColor: "#000", color: "#fff" }}
          />
        </Divider>
        <Grid xs={12} sx={{ mt: 5, mb: 10 }}>
          <Card sx={{ backgroundColor: "#000" }}>
            <CardContent>
              <Box display="flex" justifyContent="center" pt={8} pb={8}>
                <Box textAlign="center" pl={6} pr={6}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography fontSize="16px" color="#fff">
                      Initial Investment
                    </Typography>
                    <BootstrapTooltip
                      title="Initial invested amount"
                      TransitionComponent={Zoom}
                      placement="top"
                    >
                      <IconButton sx={{ color: "#fff" }}>
                        <InfoIcon />
                      </IconButton>
                    </BootstrapTooltip>
                  </Box>
                  <Typography fontSize="28px" color="#fff" fontWeight="bold">
                    {usd.format(Number(appState.user.selectedAmount))}
                  </Typography>
                </Box>
                <Box textAlign="center" pl={6} pr={6}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography fontSize="16px" color="#fff">
                      Market Value
                    </Typography>
                    <BootstrapTooltip
                      title="Total equity value held in your account"
                      TransitionComponent={Zoom}
                      placement="top"
                    >
                      <IconButton sx={{ color: "#fff" }}>
                        <InfoIcon />
                      </IconButton>
                    </BootstrapTooltip>
                  </Box>
                  <Typography fontSize="28px" color="#fff" fontWeight="bold">
                    {usd.format(
                      Number(appState.accountSummary?.equity?.equityValue),
                    )}
                  </Typography>
                </Box>
                <Box textAlign="center" pl={6} pr={6}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography fontSize="16px" color="#fff">
                      Cash Balance
                    </Typography>
                    <BootstrapTooltip
                      title="Total amount of settled and unsettled money in your account"
                      TransitionComponent={Zoom}
                      placement="top"
                    >
                      <IconButton sx={{ color: "#fff" }}>
                        <InfoIcon />
                      </IconButton>
                    </BootstrapTooltip>
                  </Box>
                  <Typography fontSize="28px" color="#fff" fontWeight="bold">
                    {usd.format(
                      Number(appState.accountSummary?.cash?.cashBalance),
                    )}
                  </Typography>
                </Box>
                <Box textAlign="center" pl={6} pr={6}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography fontSize="16px" color="#fff">
                      Total Equity
                    </Typography>
                    <BootstrapTooltip
                      title="Amount of the Market Value plus the Cash Balance"
                      TransitionComponent={Zoom}
                      placement="top"
                    >
                      <IconButton sx={{ color: "#fff" }}>
                        <InfoIcon />
                      </IconButton>
                    </BootstrapTooltip>
                  </Box>
                  <Typography fontSize="28px" color="#fff" fontWeight="bold">
                    {usd.format(Number(currentAmount))}
                  </Typography>
                </Box>
              </Box>
              {/* <Box textAlign="center" pt={7} pb={7}>
                <QuestionButton>
              <img alt="quote" src={quote} />
              Ask Question
            </QuestionButton>
              </Box> */}
            </CardContent>
          </Card>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Dashboard;
