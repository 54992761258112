import styled from 'styled-components';

export const WithdrawFundsModalWrapper = styled.div`
  text-align: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 20px;

  .row {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bank-account-select-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
`;
