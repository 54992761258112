import styled from 'styled-components';

export const SectionLayout = styled.section<{
  paddingMobile?: boolean;
  padding?: string;
}>`
  display: flex;
  flex-direction: column;
  padding: 100px 70px;

  h1 {
    letter-spacing: initial;
    font-size: 3.214rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.palette.common.black};
  }

  p {
    font-size: 1.214rem;
    font-weight: 400;
    line-height: 1.35;
    color: ${({ theme }) => theme.palette.common.black};
    letter-spacing: initial;
  }

  a {
    padding: 17px 34px;
    margin-top: 40px;
    font-size: 1.214rem;
    font-weight: 500;
    line-height: 1.23;
    text-align: center;
  }

  @media (max-width: 1150px) {
    padding: 60px 30px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: ${({ paddingMobile }) => (paddingMobile ? '0px' : '60px')} 20px
      60px 20px;

    h1 {
      font-size: 1.786rem;
      margin-bottom: 15px;
    }

    p {
      font-size: 1.143rem;
      font-weight: 400;
      line-height: 1.3;
    }

    a {
      padding: 10px 20px;
      margin-top: 25px;
    }
  }
`;
