import React from 'react';
import { Grid, Typography } from '@mui/material';
import LogoSvg from './logo.svg';
import Loader from '../../../components/Loader/Loader';

function ZohoSignLoadingPage() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: '100vh',
        minWidth: '100vw',
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: '9999',
        background: '#fff',
      }}
    >
      <Grid item xs={12} spacing={3}>
        <img src={LogoSvg} alt="logo of lading page" />
      </Grid>
      <Grid item xs={12} spacing={3} my={2}>
        <Loader />
      </Grid>
      <Grid item xs={12} spacing={3}>
        <Typography
          fontSize="1.2rem"
          fontFamily="Walsheim-Regular, sans-serif"
          mb={1}
        >
          Please wait while the page is loading.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ZohoSignLoadingPage;
