import React from 'react';
import MessageBox from 'components/MessageBox/MessageBox';
import { LinkPrimary } from 'views/SignIn/styles';

function Sorry() {
  return (
    <MessageBox
      title="Sorry."
      body={
        <>
          Based on the provided information we can’t create your account. If you
          have any questions&nbsp;
          <LinkPrimary to="contact-us">please contact us here.</LinkPrimary>
        </>
      }
    />
  );
}

export default Sorry;
