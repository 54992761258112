import axios from 'axios';
import { Store } from 'react-notifications-component';
import { APP_STATE } from 'constant';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.defaults.headers.post['Content-Type'] = 'application/json';
const { accessToken } = JSON.parse(localStorage.getItem(APP_STATE) || '{}');

if (accessToken) {
  instance.defaults.headers.common.Authorization = `MoneyToken ${accessToken}`;
}

instance.interceptors.request.use(
  (config) => {
    const { accessToken: token } = JSON.parse(
      localStorage.getItem(APP_STATE) || '{}',
    );

    if (config.headers && token) {
      config.headers.Authorization = `MoneyToken ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => response,
  (err) => {
    // const status=err.status;
    const message = err.response.data.message || err.statusText;
    Store.addNotification({
      title: 'Error!',
      message,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });

    return Promise.reject(err);
  },
);

export default instance;
