import { Button, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { SectionLayout } from 'components/layouts/sectionLayout';
import { CTA_REDIRECT_URL } from 'constant';
import messages from './messages';
import { WealthContainer, WealthWrapper, WealthImage, Summary } from './styles';
import wealthImage from './assets/wealth.jpg';

function WealthSection() {
  const intl = useIntl();

  return (
    <WealthContainer>
      <SectionLayout>
        <WealthWrapper>
          <WealthImage>
            <img
              src={wealthImage}
              alt={intl.formatMessage({ ...messages.imageAltText })}
            />
          </WealthImage>
          <Summary>
            <Typography variant="h1">
              <FormattedMessage {...messages.title} />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage {...messages.description} />
            </Typography>
            <div>
              <Button
                variant="contained"
                disableElevation
                href={CTA_REDIRECT_URL}
              >
                <FormattedMessage {...messages.button} />
              </Button>
            </div>
          </Summary>
        </WealthWrapper>
      </SectionLayout>
    </WealthContainer>
  );
}

export default WealthSection;
