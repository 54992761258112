import WelcomeSection from 'components/WelcomeSection';
import ExclusiveSection from 'components/ExclusiveSection';
import SellSmarterSection from 'components/SellSmarterSection';
import WealthSection from 'components/WealthSection';
import LearnMoreSection from 'components/LearnMoreSection';
import HowItWorksSection from 'components/HowItWorks';
import HomepageFooter from 'components/HomepageFooter';

function HomePage() {
  return (
    <div>
      <WelcomeSection />
      <ExclusiveSection />
      <SellSmarterSection />
      <WealthSection />
      <LearnMoreSection />
      <HowItWorksSection />
      <HomepageFooter />
    </div>
  );
}

export default HomePage;
