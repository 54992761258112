import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { SectionLayout } from 'components/layouts/sectionLayout';
import { CTA_REDIRECT_URL } from 'constant';
import {
  HowItWorksWrapper,
  HowItWorksImage,
  Summary,
  StepList,
  Step,
  OuterRing,
} from './styles';
import howItWorks from './assets/how-it-works.jpg';
import howItWorksMobile from './assets/how-it-works-mobile.jpg';
import messages from './messages';

function HowItWorksSection() {
  const intl = useIntl();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <SectionLayout paddingMobile>
      <HowItWorksWrapper>
        <HowItWorksImage>
          <img
            src={isDesktop ? howItWorks : howItWorksMobile}
            alt={intl.formatMessage({ ...messages.imageAltText })}
          />
        </HowItWorksImage>
        <Summary>
          <Typography variant="h1">
            <FormattedMessage {...messages.title} />
          </Typography>
          <StepList>
            <Step>
              <OuterRing>
                <Typography variant="h5">1</Typography>
              </OuterRing>
              <Typography variant="h6">
                <FormattedMessage {...messages.stepOne} />
              </Typography>
            </Step>
            <Step>
              <OuterRing>
                <Typography variant="h5">2</Typography>
              </OuterRing>
              <Typography variant="h6">
                <FormattedMessage {...messages.stepTwo} />
              </Typography>
            </Step>
            <Step>
              <OuterRing>
                <Typography variant="h5">3</Typography>
              </OuterRing>
              <Typography variant="h6">
                <FormattedMessage {...messages.stepThree} />
              </Typography>
            </Step>
            <Step>
              <OuterRing>
                <Typography variant="h5">4</Typography>
              </OuterRing>
              <Typography variant="h6">
                <FormattedMessage {...messages.stepFour} />
              </Typography>
            </Step>
          </StepList>
          <div>
            <Button
              variant="contained"
              disableElevation
              href={CTA_REDIRECT_URL}
              className="how-it-works-button"
            >
              <FormattedMessage {...messages.button} />
            </Button>
          </div>
        </Summary>
      </HowItWorksWrapper>
    </SectionLayout>
  );
}

export default HowItWorksSection;
