import { defineMessages } from 'react-intl';

export const scope = 'app.components.LearnMore';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'An expert you can trust',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Richr Money, is not just an investment platform - it{rsquo}s a guide to financial success. We understand the challenges that come with selling a home — from tons of paperwork to managing the proceeds — and built the Richr team to help navigate the entire process. Our team of experts is dedicated to ensuring smart investment decisions and achieving financial goals.',
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: 'List today and claim your 2% Richr Money cash back',
  },
  imageAltText: {
    id: `${scope}.imageAltText`,
    defaultMessage: 'learn more',
  },
});
