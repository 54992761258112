import styled from 'styled-components';
import { ReactComponent as CoinIcon } from 'assets/icons/coin.svg';

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;

  > div {
    width: 100%;
    max-width: 224px;
  }
`;

export const AmountInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  padding-top: ${({ theme }) => theme.spacing(4)};

  p {
    font-family: Walsheim-Medium, sans-serif;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

export const InfoBox = styled.div`
  background-color: white;
  padding: ${({ theme }) => theme.spacing(3.5)};
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.09);
`;

export const Consecutive = styled.div`
  padding: ${({ theme }) => theme.spacing(4)};
  background-color: rgba(0, 212, 161, 0.08);
  margin: ${({ theme }) => theme.spacing(4)} 0;
`;

export const CoinIconWhite = styled(CoinIcon)`
  g {
    fill: white;
  }
`;

export const LocationImage = styled.img`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const BoxContainer = styled.div`
  background: rgba(109, 109, 109, 0.04);
`;

export const QuestionButton = styled.button`
  border: 2px solid #00d4a1;
  display: inline-block;
  width: 287px;
  border-radius: 6px;
  text-align: center;
  font-size: 1.3rem;
  line-height: ${({ theme }) => theme.spacing(4)};
  height: ${({ theme }) => theme.spacing(4)};
  color: #000000;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(109, 109, 109, 0.04);
    font-size: 1.5rem;
  }

  img {
    width: ${({ theme }) => theme.spacing(3.5)};
    margin-right: 16px;
    position: relative;
    top: 5px;
  }
`;
