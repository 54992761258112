import React, { useEffect, useState } from 'react';
import { PlaidAccount, PlaidLinkOnExit, usePlaidLink } from 'react-plaid-link';
import { plaid } from '../../API/plaid';

type useCustomPlaidLinkProps = {
  handleSuccess: (account: PlaidAccount) => void;
  onExit: PlaidLinkOnExit;
};

const useCustomPlaidLink = ({
  handleSuccess,
  onExit,
}: useCustomPlaidLinkProps) => {
  const [linkToken, setLinkToken] = useState(null);

  useEffect(() => {
    plaid.createLink().then(({ data }) => {
      setLinkToken(data.linkToken);
    });
  }, []);

  const onSuccess = React.useCallback(
    (publicToken, { accounts, institution }) => {
      plaid
        .exchangePublicToken(
          JSON.stringify({
            publicToken,
            institutionId: institution.institution_id,
            institutionName: institution.name,
            accounts: accounts.map(({ id, name }: typeof accounts[0]) => ({
              accountId: id,
              name,
            })),
          })
        )
        .then(() => {
          handleSuccess(accounts[0]);
        });
    },
    [handleSuccess]
  );

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken,
    onSuccess,
    onExit,
  };

  return usePlaidLink(config);
};

export default useCustomPlaidLink;
