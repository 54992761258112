import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OnboardState } from "store/OnboardState/OnboardState";
import { ReactComponent as InvestmentImage } from "../assets/investment.svg";
import { ReactComponent as ConservativeImage } from "../../../assets/icons/conservative.svg";
import { ReactComponent as ModerateImage } from "../assets/moderate.svg";
import { ReactComponent as HyperAggressive } from "../assets/hyper-aggressive.svg";
import { Options } from "./styles";

function InvestmentGoals() {
  const { updateOnboard, setCalculatorData, onboard } =
    OnboardState.useContainer();
  const navigate = useNavigate();
  const [investment] = useState(onboard.calculatorData?.investmentAmount);
  const [numberOfTimes] = useState(
    onboard.calculatorData?.numberOfTimesInMonth ?? 12,
  );
  const [period] = useState(onboard.calculatorData?.investmentPeriod);
  const [percent, setPercent] = useState<number>(
    onboard.calculatorData?.interestRate || 4,
  );
  const [active, setActive] = useState<string>("small");
  const [selectedOption, setSelectedOption] = useState<string[]>([
    "Conservative",
    "2-4% return",
  ]);

  const percentageHandler = (
    val: number,
    name: string,
    label: string,
    desc: string,
  ) => {
    setActive(name);
    setPercent(val);
    setSelectedOption([label, desc]);
  };

  const futureBalance = useMemo(
    () =>
      investment *
      (1 + percent / 100 / numberOfTimes) ** (numberOfTimes * period),
    [investment, numberOfTimes, percent, period],
  );

  const submitHandler = (event: any) => {
    event.preventDefault();
    setCalculatorData({
      interestRate: percent,
      futureBalance,
      investmentOption: selectedOption,
    });
    updateOnboard({ step: 9 });
    navigate("/recommended-investments");
  };

  useEffect(() => {
    if (onboard.calculatorData.interestRate === 10) {
      percentageHandler(10, "big", "Hyper Aggressive", "8-10% return");
    } else if (onboard.calculatorData.interestRate === 6) {
      percentageHandler(6, "middle", "Moderate", "5-6% return");
    } else {
      percentageHandler(4, "small", "Conservative", "2-4% return");
    }
  }, [onboard.calculatorData.interestRate]);

  return (
    <>
      <div>
        <InvestmentImage />
      </div>
      <Box my={2}>
        <Typography
          variant="h4"
          fontFamily="Walsheim-Light"
          display="inline-block"
        >
          Recommended&nbsp;
        </Typography>
        <Typography variant="h4" display="inline-block">
          Investment Opportunities
        </Typography>
      </Box>
      <Typography
        variant="body2"
        my={3}
        mx="auto"
        fontSize="0.875rem"
        sx={{ width: ["100%", "60%"] }}
      >
        Based on the provided information we suggest you these 3 investment
        options.
      </Typography>
      <form>
        <Options>
          <Button
            className={active === "small" ? "active" : ""}
            fullWidth
            variant="outlined"
            size="large"
            onClick={() =>
              percentageHandler(4, "small", "Conservative", "2-4% return")
            }
          >
            <ConservativeImage />
            <Typography variant="body2" fontFamily="Walsheim-Bold">
              Conservative
            </Typography>
            <Typography variant="body2" mt={1}>
              2-4% return
            </Typography>
          </Button>
          <Button
            className={active === "middle" ? "active" : ""}
            fullWidth
            variant="outlined"
            size="large"
            onClick={() =>
              percentageHandler(6, "middle", "Moderate", "5-6% return")
            }
          >
            <ModerateImage />
            <Typography variant="body2" fontFamily="Walsheim-Bold">
              Moderate
            </Typography>
            <Typography variant="body2" mt={1}>
              5-6% return
            </Typography>
          </Button>
          <Button
            className={active === "big" ? "active" : ""}
            fullWidth
            variant="outlined"
            size="large"
            onClick={() =>
              percentageHandler(10, "big", "Hyper Aggressive", "8-10% return")
            }
          >
            <HyperAggressive />
            <Typography variant="body2" fontFamily="Walsheim-Bold">
              Hyper Aggressive
            </Typography>
            <Typography variant="body2" mt={1}>
              8-10% return
            </Typography>
          </Button>
        </Options>
        <Box mt={3} mb={1}>
          <Typography
            variant="h6"
            fontFamily="Walsheim-Regular"
            display="inline-block"
          >
            Selected option:&nbsp;
          </Typography>
          <Typography variant="h6" display="inline-block">
            {selectedOption[0]}
          </Typography>
        </Box>
        <Typography variant="body2" mt={3} mb={2} fontSize="0.875rem">
          You change change your risk tolerance at anytime.
        </Typography>
        <Button
          variant="contained"
          type="submit"
          size="large"
          sx={{ mt: 3, width: ["100%", "70%"] }}
          onClick={submitHandler}
        >
          Continue
        </Button>
      </form>
    </>
  );
}

export default InvestmentGoals;
