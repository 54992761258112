import React, { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { every } from 'lodash-es';
import API from 'API';
import { ONBOARD_STATE } from 'constant';
import { ReactComponent as QuestionImage } from '../assets/question.svg';
import { AnswerWrapper } from './styles';
import { OnboardState } from '../../../store/OnboardState/OnboardState';

function Essentials() {
  const { incrementStep } = OnboardState.useContainer();

  const navigate = useNavigate();

  const [requlatoryQuestions, setRequlatoryQuestions] = useState({
    companyDirector: null,
    employedByAnotherFirm: null,
    politicallyExposedPerson: null,
  });

  const handleAnswerRegulatoryQuestion = (
    question: keyof typeof requlatoryQuestions,
    value: boolean,
  ) => {
    setRequlatoryQuestions((prevState) => ({
      ...prevState,
      [question]: value,
    }));
  };

  const showAlert = () => {
    localStorage.removeItem(ONBOARD_STATE);
    navigate('/onboard/sorry');
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (Object.values(requlatoryQuestions).some((val) => val === true)) {
      showAlert();
    } else {
      API.Onboarding.regulatoryQuestion({
        data: requlatoryQuestions,
      })
        .then(() => {
          incrementStep();
          navigate('/onboard/employment-info');
        })
        .catch((err) => {
          if (err.response.status === 403) {
            showAlert();
          }
        });
    }
  };

  const canSubmit = () =>
    every(requlatoryQuestions, (value) => typeof value === 'boolean');

  return (
    <>
      <div>
        <QuestionImage />
      </div>
      <Box my={2}>
        <Typography
          variant="h4"
          fontFamily="Walsheim-Light"
          display="inline-block"
        >
          Few More&nbsp;
        </Typography>
        <Typography variant="h4" display="inline-block">
          Questions
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Typography variant="h6" mt={4} mb={1} fontFamily="Walsheim-Medium">
          Do you own more than 10% of a publicly held company or are you a
          senior executive at a publicly traded company?
        </Typography>
        <AnswerWrapper>
          <Button
            sx={{ my: 1 }}
            className={requlatoryQuestions.companyDirector ? 'active' : ''}
            fullWidth
            variant="outlined"
            size="large"
            value={1}
            onClick={() =>
              handleAnswerRegulatoryQuestion('companyDirector', true)
            }
          >
            Yes
          </Button>
          <Button
            sx={{ my: 1 }}
            className={
              requlatoryQuestions.companyDirector === false ? 'active' : ''
            }
            fullWidth
            variant="outlined"
            size="large"
            value={0}
            onClick={() =>
              handleAnswerRegulatoryQuestion('companyDirector', false)
            }
          >
            No
          </Button>
        </AnswerWrapper>
        <Typography variant="h6" mt={3} mb={1} fontFamily="Walsheim-Medium">
          Do you work for a stock exchange or brokerage?
        </Typography>
        <AnswerWrapper>
          <Button
            sx={{ my: 1 }}
            className={
              requlatoryQuestions.employedByAnotherFirm ? 'active' : ''
            }
            fullWidth
            variant="outlined"
            size="large"
            value={1}
            onClick={() =>
              handleAnswerRegulatoryQuestion('employedByAnotherFirm', true)
            }
          >
            Yes
          </Button>
          <Button
            sx={{ my: 1 }}
            className={
              requlatoryQuestions.employedByAnotherFirm === false
                ? 'active'
                : ''
            }
            fullWidth
            variant="outlined"
            size="large"
            value={0}
            onClick={() =>
              handleAnswerRegulatoryQuestion('employedByAnotherFirm', false)
            }
          >
            No
          </Button>
        </AnswerWrapper>
        <Typography variant="h6" mt={3} mb={1} fontFamily="Walsheim-Medium">
          Do you hold a public office or are you currently running for a public
          office?
        </Typography>
        <AnswerWrapper>
          <Button
            sx={{ my: 1 }}
            className={
              requlatoryQuestions.politicallyExposedPerson ? 'active' : ''
            }
            fullWidth
            variant="outlined"
            size="large"
            value={1}
            onClick={() =>
              handleAnswerRegulatoryQuestion('politicallyExposedPerson', true)
            }
          >
            Yes
          </Button>
          <Button
            sx={{ my: 1 }}
            className={
              requlatoryQuestions.politicallyExposedPerson === false
                ? 'active'
                : ''
            }
            fullWidth
            variant="outlined"
            size="large"
            value={0}
            onClick={() =>
              handleAnswerRegulatoryQuestion('politicallyExposedPerson', false)
            }
          >
            No
          </Button>
        </AnswerWrapper>
        <Button
          variant="contained"
          type="submit"
          size="large"
          fullWidth
          disabled={!canSubmit()}
          sx={{ mt: 3 }}
        >
          Continue
        </Button>
      </form>
    </>
  );
}

export default Essentials;
