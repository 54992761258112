import React from 'react';
import Helmet from 'react-helmet';
import { SignupFullForm } from 'store/signup/SignupFullForm';
import PaperLayout from '../../components/PaperLayout/PaperLayout';

function Signup() {
  return (
    <SignupFullForm.Provider>
      <Helmet>
        <style>
          {`
              body {
                  background-color: #0082BC;
              }
           `}
        </style>
      </Helmet>
      <PaperLayout />
    </SignupFullForm.Provider>
  );
}

export default Signup;
