import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';

function EmailSuccess() {
  const navigate = useNavigate();

  return (
    <div>
      <Typography variant="h6">Success!</Typography>
      <Typography variant="body2" mt={2} mb={2}>
        Please check your inbox in a few minutes and follow the instruction from
        the email message.
      </Typography>
      <Button
        variant="contained"
        type="submit"
        size="large"
        fullWidth
        sx={{ mt: 3, mb: 2 }}
        onClick={() => navigate('/signin')}
      >
        GO TO LOGIN PAGE
      </Button>
    </div>
  );
}

export default EmailSuccess;
