import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { useNavigate } from 'react-router-dom';
import { AppState } from 'store/app/AppState';

type SuccessProps = {
  title: React.ReactNode;
  body: React.ReactNode;
};

function Success({ title, body }: SuccessProps) {
  const navigate = useNavigate();
  const { logOut } = AppState.useContainer();

  const logoutAction = () => {
    logOut();
    navigate('/');
  };

  return (
    <>
      <div>
        <Logo />
      </div>
      <Typography variant="h4" my={2}>
        {title}
      </Typography>
      <Box mx="auto">
        <Typography variant="body2" my={3}>
          {body}
        </Typography>
        <Button
          onClick={() => logoutAction()}
          variant="contained"
          type="submit"
          size="large"
          fullWidth
          sx={{ mt: 3 }}
        >
          Go Home
        </Button>
      </Box>
    </>
  );
}

export default Success;
