import { defineMessages } from 'react-intl';

export const scope = 'app.components.ExclusiveSection';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage:
      'Exclusive for Richr Homes Sellers: Invest Your Proceeds with Richr Money',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'When selling with Richr you gain access to the exclusive opportunity to invest your 2% cash back with Richr Money and see your savings grow. Choose from one of three unique funds that offer something for every type of investor and take comfort in knowing Richr will be there to help every step of the way',
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: 'Sell Your Home with Richr Homes',
  },
  serenity: {
    id: `${scope}.button`,
    defaultMessage: 'Serenity',
  },
  harmony: {
    id: `${scope}.button`,
    defaultMessage: 'Harmony',
  },
  velocity: {
    id: `${scope}.button`,
    defaultMessage: 'Velocity',
  },
  imageAltText: {
    id: `${scope}.imageAltText`,
    defaultMessage: 'exclusive section',
  },
});
