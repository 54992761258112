import styled from 'styled-components';

export const ReadyToStart = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.common.black};

  .description-link {
    padding: 0;
    margin-top: 0;
    font-size: 1.214rem;
    font-weight: 400;
    line-height: 1.35;
    text-decoration: none;
  }

  .ready-to-start-section {
    padding: 90px 70px 60px;
  }

  h1,
  p,
  h6 {
    color: ${({ theme }) => theme.palette.common.white};
  }

  hr {
    border-width: 1px;
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    .ready-to-start-section {
      padding: 60px 20px;
    }
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  margin-bottom: 60px;

  hr {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    margin-bottom: 20px;

    hr {
      margin: 40px 0;
      background-color: ${({ theme }) => theme.palette.secondary.light};
    }
  }
`;

export const FooterDescription = styled.div`
  font-size: 1.214rem;
  line-height: 1.35;
  margin-left: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;

  h6 {
    color: ${({ theme }) => theme.palette.secondary.dark};
    font-size: 1.214rem;
    line-height: 1.35;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-left: 0;
    width: 100%;
  }
`;

export const BottomSectionWrapper = styled.div`
  margin-top: 60px;

  p {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }

  a {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const Info = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
    flex-direction: column;
    text-align: center;
  }
`;

export const Copyright = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 11px;
    font-size: 1.214rem;
    line-height: 1.35;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    align-items: center;
  }
`;

export const Legal = styled.div`
  margin-top: 43px;

  p {
    font-size: 1rem;
    line-height: 1.35;
  }
`;

export const TermsAndPrivacy = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 16px;
  }

  a {
    margin-bottom: 17px;
    font-size: 1.214rem;
    line-height: 1.35;
    padding: initial;
    margin-top: 0;
    font-weight: 400;
    text-decoration: none;
    text-align: initial;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-top: 29px;

    p {
      margin-bottom: 18px;
    }

    a {
      margin-bottom: 19px;
      text-align: center;
    }
  }
`;

export const Summary = styled.div`
  padding-right: 70px;

  .footer-description {
    width: 85%;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    padding-right: 0;

    .footer-description {
      width: 100%;
    }
  }
`;
