import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as Logo } from 'assets/icons/logo-horizontal.svg';
import { ReactComponent as Avatar } from 'assets/icons/avatar.svg';
import { ReactComponent as RightArrow } from 'assets/icons/right-arrow.svg';
import { SIGN_IN_ROUTE } from 'constant/routes';
import messages from './messages';
import { ButtonWrapper, Wrapper, LogoWrapper } from './styles';

function Header() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  function handleButtonClick() {
    navigate(SIGN_IN_ROUTE);
  }

  function renderSignInButton() {
    if (isDesktop) {
      return (
        <ButtonWrapper>
          <Button
            disableElevation
            variant="contained"
            endIcon={<RightArrow />}
            onClick={handleButtonClick}
          >
            <FormattedMessage {...messages.signIn} />
          </Button>
        </ButtonWrapper>
      );
    }

    return (
      <ButtonWrapper onClick={handleButtonClick}>
        <Avatar />
      </ButtonWrapper>
    );
  }

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      {renderSignInButton()}
    </Wrapper>
  );
}

export default Header;
