import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import styled from 'styled-components';
import { isComponentApplyStepStyling } from 'utils/hooks/onboarding';

const PaperContainer = styled(Container)`
  text-align: center;
  margin: 60px 0;
  background-color: #fff;
  padding: 48px 32px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  max-width: 688px;

  .MuiSelect-select {
    text-align: left;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
`;

function PaperLayout() {
  if (isComponentApplyStepStyling()) {
    return (
      <Wrapper>
        <PaperContainer maxWidth={false} disableGutters>
          <Outlet />
        </PaperContainer>
      </Wrapper>
    );
  }
  return <Outlet />;
}

export default PaperLayout;
