import React, { MouseEventHandler } from 'react';
import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { PlaidAccount } from 'react-plaid-link';
import Account from './Account/Account';

type AddAccountProps = {
  accounts: PlaidAccount[];
  ready: boolean;
  onAdd: MouseEventHandler<HTMLButtonElement>;
  onDelete: (accountId: string) => void;
};

function AddAccount({ accounts, ready, onAdd, onDelete }: AddAccountProps) {
  return (
    <>
      <Typography my={5} variant="h4" fontSize="2.25rem">
        Linked Bank Account
      </Typography>
      {accounts.map((account) => (
        <Account key={account.id} account={account} onDelete={onDelete} />
      ))}
      <Button
        disabled={!ready}
        sx={{ fontSize: '1.125rem', color: '#000', mb: 2 }}
        startIcon={
          <AddIcon sx={{ fontSize: '34px!important' }} color="primary" />
        }
        onClick={onAdd}
      >
        ADD ANOTHER ACCOUNT
      </Button>
    </>
  );
}

export default AddAccount;
