import React, { useEffect, useState } from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { PlaidAccount } from 'react-plaid-link';
import NoAccount from './NoAccount/NoAccount';
import useCustomPlaidLink from '../../utils/hooks/useCustomPlaidLink';
import { plaid } from '../../API/plaid';
import AddAccount from './AddAccount/AddAccount';
import ErrorAccount from './ErrorAccount/ErrorAccount';
import ScceedAccount from './ScceedAccount/ScceedAccount';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 552,
  minHeight: 400,
  bgcolor: '#fff',
  borderRadius: '6px',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

function ModalNoBankAccount({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) {
  const [linkedAccounts, setLinkedAccounts] = useState<PlaidAccount[]>([]);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [linkedNewAccount, setLinkedNewAccount] = useState<PlaidAccount>();

  const { open, ready } = useCustomPlaidLink({
    handleSuccess: (account) => setLinkedNewAccount(account),
    onExit: (error) => {
      if (error) {
        setErrorOccurred(true);
      }
    },
  });

  useEffect(() => {
    if (isOpen) {
      plaid.getAccounts().then(({ data }) => {
        setLinkedAccounts(data);
      });
    }
  }, [handleClose, isOpen]);

  const handleDeleteBankAccount = async (accountId: string) => {
    await plaid.unlinkBankAccount(accountId);
    handleClose();
  };

  let content = <NoAccount ready={ready} onClick={() => open()} />;
  if (errorOccurred) content = <ErrorAccount />;
  else if (linkedNewAccount)
    content = (
      <ScceedAccount onClick={handleClose} account={linkedNewAccount} />
    );
  else if (linkedAccounts.length > 0)
    content = (
      <AddAccount
        ready={ready}
        onAdd={() => open()}
        onDelete={handleDeleteBankAccount}
        accounts={linkedAccounts}
      />
    );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: 'absolute', right: '25px', top: '16px' }}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {content}
      </Box>
    </Modal>
  );
}

export default ModalNoBankAccount;
