import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as MoneyImage } from 'assets/icons/money.svg';

function ErrorAccount() {
  return (
    <>
      <Box my={3}>
        <MoneyImage />
      </Box>
      <Typography variant="h4" fontSize="2.25rem">
        Can’t Link Provided Bank Account.
      </Typography>
      <Typography variant="body2" mt={3} mb={4}>
        <Typography
          variant="body2"
          fontFamily="Walsheim-Bold, sans-serif"
          component="span"
        >
          Something went wrong{' '}
        </Typography>
        when linking the provided account number to your Richr Money account.
        <br /> <br />
        Try again later. If this error will happen again - please contact as at
        <Typography
          variant="body2"
          fontFamily="Walsheim-Bold, sans-serif"
          component="span"
        >
          {' '}
          support@richrmoney.com
        </Typography>
      </Typography>
    </>
  );
}

export default ErrorAccount;
