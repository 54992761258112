import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { SignupFullForm } from 'store/signup/SignupFullForm';
import { AppState } from 'store/app/AppState';
import API from 'API';

type FormInputType = {
  code: string;
  phone: string;
};

function ConfirmIdentity() {
  const navigate = useNavigate();
  const { signupFullForm, updateSignupFullForm, resetSignupFormState } =
    SignupFullForm.useContainer();
  const { setAccessToken } = AppState.useContainer();

  const {
    register,
    unregister,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = useForm<FormInputType>();

  const [phoneNumberSubmitted, setPhoneNumberSubmitted] = useState(false);

  useEffect(() => {
    if (signupFullForm.inviteCode && !signupFullForm.password) {
      navigate(`/signup/${signupFullForm.inviteCode}`);
    }
  }, [signupFullForm, navigate]);

  const sendVerificationCode = (phoneNum: string) => {
    API.User.sendVerificationCode({
      phoneNumber: parsePhoneNumber(phoneNum)?.number,
    });
  };

  const onSubmit: SubmitHandler<FormInputType> = async (data) => {
    if (!phoneNumberSubmitted) {
      sendVerificationCode(data.phone);
      updateSignupFullForm({
        phoneNumber: parsePhoneNumber(data.phone)?.number || '',
      });
      setPhoneNumberSubmitted(true);
    } else {
      updateSignupFullForm({
        verificationCode: data.code,
      });
      const {
        data: { key, moneyUser },
      } = await API.User.signUp({
        ...signupFullForm,
        verificationCode: data.code,
      });
      setAccessToken({ key, moneyUser });
      resetSignupFormState();
      navigate('/onboard/info');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <PhoneIcon />
      </div>
      <Box my={2}>
        <Typography
          variant="h4"
          fontFamily="Walsheim-Light"
          display="inline-block"
        >
          Confirm&nbsp;
        </Typography>
        <Typography variant="h4" display="inline-block">
          Your Identity
        </Typography>
      </Box>
      <Typography variant="body2" my={3}>
        {phoneNumberSubmitted
          ? 'Please enter the 8 digit code from your texts'
          : 'Please enter your cellphone number to set up two factor authentication'}
      </Typography>
      <Controller
        name="phone"
        control={control}
        rules={{
          validate: {
            test: (phoneNum) =>
              phoneNum && isValidPhoneNumber(phoneNum)
                ? true
                : 'Please enter valid phone number',
          },
        }}
        render={({ field }) => (
          <MuiPhoneNumber
            {...field}
            sx={{ width: ['100%', '70%'] }}
            label="phone number"
            variant="outlined"
            defaultCountry="us"
            margin="normal"
            error={Boolean(errors.phone)}
            helperText={errors.phone?.message}
            onChange={(phoneNum) => {
              if (phoneNumberSubmitted) {
                setPhoneNumberSubmitted(false);
                unregister('code');
              }
              return field.onChange(phoneNum);
            }}
          />
        )}
      />
      {phoneNumberSubmitted && (
        <TextField
          inputProps={{ maxLength: 8 }}
          type="tel"
          sx={{ width: ['100%', '70%'] }}
          {...register('code', {
            required: {
              value: true,
              message: 'please enter 8 digit code',
            },
            pattern: {
              value: /^\d{8}$/i,
              message: 'please enter 8 digit code',
            },
            minLength: {
              value: 8,
              message: 'please enter 8 digit code',
            },
          })}
          label="8 Digits SMS code"
          margin="normal"
          error={Boolean(errors.code)}
          helperText={errors.code?.message}
        />
      )}
      <Button
        variant="contained"
        type="submit"
        size="large"
        fullWidth
        sx={{ mt: 3, mb: 2, width: ['100%', '70%'] }}
      >
        Confirm and Continue
      </Button>
      {phoneNumberSubmitted && (
        <FormHelperText>
          Didn’t recieve the SMS?
          <Button onClick={() => sendVerificationCode(getValues('phone'))}>
            Resend!
          </Button>
        </FormHelperText>
      )}
    </form>
  );
}

export default ConfirmIdentity;
