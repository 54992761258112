import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { usd } from 'utils/helpers/currencyFormatter';
import { SubmitHandler, useForm } from 'react-hook-form';
import API from 'API';
import { Store } from 'react-notifications-component';
import {
  Content,
  WithdrawFundsModalWrapper,
} from './WithdrawFundsModal.styles';
// import { PlaidAccount } from 'react-plaid-link';
// import useCustomPlaidLink from '../../utils/hooks/useCustomPlaidLink';
import { plaid } from '../../API/plaid';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 552,
  minHeight: 400,
  bgcolor: '#fff',
  borderRadius: '6px',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

function WithdrawFundsModal({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) {
  const [bankAccounts, setBankAccounts] = useState<any[]>([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState('');
  const [cashAvailableForWithdrawal, setCashAvailableForWithdrawal] =
    useState(0);
  const [isSuccessWithdrawal, setIsSuccessWithdrawal] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<any>({ reValidateMode: 'onChange' });

  useEffect(() => {
    API.User.getAccountSummary().then((res) => {
      setCashAvailableForWithdrawal(
        (res.data.accountSummary?.cash?.cashBalance || 0) +
          (res.data.accountSummary?.equity?.equityValue || 0)
      );
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      plaid.getAccounts().then(({ data }) => {
        setBankAccounts(data);
      });
    }
  }, [handleClose, isOpen]);

  const liquidateAllAssets = async () => {
    try {
      await API.plaid.withdrawFunds({
        dwBankAccountId: selectedBankAccount,
        liquidate: true,
      });
      return Store.addNotification({
        title: 'Redemption created successfully!',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } catch (err) {
      return Store.addNotification({
        title: 'Error!',
        message: 'Something went wrong',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      await API.plaid.createFunding(
        selectedBankAccount,
        data.withdrawAmount,
        'redemption'
      );
      Store.addNotification({
        title: 'Redemption created successfully!',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
      return setIsSuccessWithdrawal(true);
    } catch (err) {
      return Store.addNotification({
        title: 'Error!',
        message: 'Something went wrong',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  };

  const handleBankAccountChange = (event: SelectChangeEvent) => {
    setSelectedBankAccount(event.target.value);
  };

  const renderSuccessRequest = () => (
    <Content>
      <Typography variant="h4" sx={{ fontWeight: 600, mb: 2 }}>
        Your withdrawal request has been sent to your bank account
      </Typography>
      <div className="row">
        <Typography variant="body1" sx={{ fontWeight: 400, mb: 2 }}>
          Please allow 3-5 business days for the funds to arrive.
        </Typography>
        <Button
          variant="contained"
          type="button"
          size="large"
          fullWidth
          sx={{ mt: 3, mb: 1 }}
          onClick={handleClose}
        >
          CONTINUE
        </Button>
      </div>
    </Content>
  );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', right: '25px', top: '16px' }}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <WithdrawFundsModalWrapper>
            {isSuccessWithdrawal ? (
              renderSuccessRequest()
            ) : (
              <Content>
                <Typography
                  variant="h4"
                  fontFamily="Walsheim-Bold, sans-serif"
                  lineHeight="unset"
                >
                  Withdraw Funds
                </Typography>
                <div className="row">
                  <Typography variant="body1">
                    Available Funds for Withdrawal:
                  </Typography>
                  <Typography
                    variant-="body1"
                    fontFamily="Walsheim-Medium, sans-serif"
                  >
                    {usd.format(cashAvailableForWithdrawal || 0)}
                  </Typography>
                </div>
                <div className="row">
                  <Typography sx={{ marginBottom: '10px' }} variant="body2">
                    AMOUNT TO WITHDRAW
                  </Typography>
                  <TextField
                    fullWidth
                    label="Amount"
                    type="number"
                    {...register('withdrawAmount', {
                      required: true,
                    })}
                    error={Boolean(errors.withdrawAmount)}
                    helperText={errors.withdrawAmount?.message}
                  />
                </div>
                <div className="row bank-account-select-wrapper">
                  <Typography sx={{ marginBottom: '10px' }} variant="body2">
                    BANK ACCOUNT
                  </Typography>
                  <Select
                    fullWidth
                    labelId="bank-select-label"
                    id="bank-select"
                    value={selectedBankAccount}
                    displayEmpty
                    label="BANK ACCOUNT"
                    defaultValue=""
                    {...register('bankAccount', {
                      required: true,
                    })}
                    error={Boolean(errors.bankAccount)}
                    onChange={handleBankAccountChange}
                  >
                    <MenuItem value="">Select Bank Account</MenuItem>
                    {(bankAccounts || []).map(
                      ({ id, name, dwBankAccountId }) => (
                        <MenuItem key={id} value={dwBankAccountId}>
                          {name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  <Typography sx={{ marginTop: '30px' }} variant="subtitle1">
                    There is a $0.25 fixed fee per withdrawal. The funds will be
                    deducted to your bank account within the next 3-5 working
                    days.
                  </Typography>
                </div>
                <div className="row">
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    fullWidth
                    disabled={
                      cashAvailableForWithdrawal <= 0 ||
                      Number(watch('withdrawAmount')) >
                        Number(cashAvailableForWithdrawal) ||
                      !watch('withdrawAmount')
                    }
                    sx={{ mt: 3 }}
                  >
                    CONFIRM AND WITHDRAW FUNDS
                  </Button>
                  <Button
                    variant="contained"
                    // type="submit"
                    onClick={() => liquidateAllAssets()}
                    size="large"
                    fullWidth
                    disabled={
                      cashAvailableForWithdrawal <= 0 || !selectedBankAccount
                    }
                    sx={{ mt: 1, mb: 1 }}
                  >
                    WITHDRAW ALL EQUITY POSITIONS
                  </Button>
                  <Button
                    variant="outlined"
                    type="button"
                    size="large"
                    fullWidth
                    sx={{ mt: 2, mb: 1 }}
                    onClick={() => {
                      handleClose();
                      reset();
                      setSelectedBankAccount('');
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Content>
            )}
          </WithdrawFundsModalWrapper>
        </Box>
      </form>
    </Modal>
  );
}

export default WithdrawFundsModal;
