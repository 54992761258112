export function getBucketLevelInterestRate(bucketLevel: string): number {
  switch (bucketLevel) {
    case "LOW":
      return 4;
    case "MODERATE":
      return 6;
    case "HIGH":
      return 8;
    default:
      return 4;
  }
}
