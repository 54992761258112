import { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SignedInHeader from 'components/Header/SignedInHeader';
import { ReactComponent as CoinIcon } from 'assets/icons/coin.svg';
import Footer from 'components/Footer/Footer';
import { ReactComponent as ConservativeImg } from 'assets/icons/conservative.svg';
import { OnboardState } from 'store/OnboardState/OnboardState';
import { useNavigate } from 'react-router-dom';
import API from 'API';
import { usd } from 'utils/helpers/currencyFormatter';
import { Store } from 'react-notifications-component';
import {
  AmountInfo,
  InfoBox,
  CoinIconWhite,
  ButtonsWrapper,
  LocationImage,
  Consecutive,
} from './styles';
import houseImage from './assets/house.jpeg';

const initialHomeDetails = {
  city: '',
  closingDate: null,
  image: '',
  richrSavings: 0,
  salePrice: 0,
  state: '',
  streetNumber: '',
  zipCode: '',
  dealTitle: '',
  unparsedAddress: '',
};

function Dashboard() {
  const { updateOnboard, onboard, setCalculatorData } =
    OnboardState.useContainer();
  const navigate = useNavigate();
  const [investment, setInvestment] = useState<number>(0);
  const [homeDetails, setHomeDetails] = useState(initialHomeDetails);
  const [period, setPeriod] = useState<number>(
    onboard.calculatorData?.investmentPeriod || 30,
  );
  const interestRate = onboard.calculatorData?.interestRate || 4;
  const numberOfTimes = onboard.calculatorData?.numberOfTimesInMonth || 12;

  useEffect(() => {
    API.Onboarding.getHomeDetails().then((res) => {
      const { data } = res;
      setHomeDetails(data);
      setInvestment(data.richrSavings);

      if (!investment) {
        setCalculatorData({
          investmentAmount: data.richrSavings,
          homeSalePrice: data.salePrice,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const futureBalance = useMemo(
    () =>
      investment *
      (1 + interestRate / 100 / numberOfTimes) ** (numberOfTimes * period),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      interestRate,
      investment,
      numberOfTimes,
      period,
      onboard?.calculatorData?.investmentOption,
    ],
  );

  const addingCalculatorData = () => {
    setCalculatorData({
      investmentPeriod: period,
      investmentAmount: investment,
      numberOfTimesInMonth: numberOfTimes,
      interestRate,
      futureBalance,
    });
  };

  const goToInvestmentGoals = () => {
    updateOnboard({ step: 10 });
    addingCalculatorData();
    navigate('/investment-goals');
  };

  const approveHandler = () => {
    API.Onboarding.createDriveWealthUser()
      .then(() => API.Onboarding.createDriveWealthAccount())
      .catch((err) => {
        Store.addNotification({
          title: 'Error!',
          message: err,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });

    addingCalculatorData();
    updateOnboard({ step: 11 });
    navigate('/recommende-investments');
  };

  return (
    <>
      <Box bgcolor="rgba(109,109,109,0.04)">
        <Container maxWidth="xl">
          <SignedInHeader />
        </Container>
      </Box>
      <Container maxWidth="xl" sx={{ mt: 11, mb: 12 }}>
        <Grid container columnSpacing={19} rowSpacing={12}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Typography
                variant="h3"
                fontSize="3rem"
                fontFamily="Walsheim-Regular, sans-serif"
              >
                Claim
              </Typography>
              &nbsp;&nbsp;&nbsp;
              <Typography variant="h3" fontSize="3rem">
                Your {usd.format(homeDetails.richrSavings)}
              </Typography>
              &nbsp;&nbsp;&nbsp;
              <Typography
                variant="h3"
                fontSize="3rem"
                fontFamily="Walsheim-Regular, sans-serif"
              >
                Savings!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <InfoBox>
              <LocationImage
                src={homeDetails.image ? homeDetails.image : houseImage}
                alt="location"
              />
              <div>
                <Typography
                  variant="h4"
                  fontSize="2.25rem"
                  fontFamily="Walsheim-Medium, sans-serif"
                >
                  {homeDetails.unparsedAddress}
                </Typography>
                <Typography fontFamily="Walsheim-Medium, sans-serif">
                  {homeDetails.closingDate}
                </Typography>
              </div>
              <AmountInfo>
                <div>
                  <Typography variant="body2">Sale Price</Typography>
                  <Typography variant="body2">RICHR SAVINGS</Typography>
                  <Typography variant="body2">NET PROCEEDS</Typography>
                </div>
                <div>
                  <Typography variant="body2">
                    {usd.format(homeDetails.salePrice)}
                  </Typography>
                  <Typography variant="body2">
                    {usd.format(homeDetails.richrSavings)}
                  </Typography>
                  <Typography color="primary.main" variant="body2">
                    {usd.format(homeDetails.salePrice)}
                  </Typography>
                </div>
              </AmountInfo>
            </InfoBox>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2" mt={3} lineHeight="48px">
              Congratulations on the pending sale of your home! <br />
              <Typography
                variant="body2"
                fontFamily="Walsheim-Bold, sans-serif"
                textAlign="center"
                component="span"
              >
                Please check the risk tolerance settings below.{' '}
              </Typography>
              You can change the risk tolerance or view the suggested list of
              the funds.
            </Typography>
            <Consecutive>
              <Box display="flex" alignItems="center">
                <ConservativeImg width={52} height={71} />
                <Box ml={3}>
                  <Typography variant="h4" fontSize="2.25rem">
                    {onboard?.calculatorData?.investmentOption
                      ? onboard?.calculatorData?.investmentOption[0]
                      : 'Conservative'}
                  </Typography>
                  <Typography>
                    {onboard?.calculatorData?.investmentOption
                      ? onboard?.calculatorData?.investmentOption[1]
                      : '2-4% return'}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Button
                  size="large"
                  sx={{
                    color: '#000',
                    backgroundColor: 'white',
                    fontFamily: 'Walsheim-Medium, sans-serif',
                    mt: 3,
                  }}
                  startIcon={<CoinIcon />}
                  variant="outlined"
                  onClick={goToInvestmentGoals}
                >
                  See Other Investment Recommendations
                </Button>
              </Box>
            </Consecutive>
            <ButtonsWrapper>
              <div>
                <Typography
                  variant="body2"
                  fontSize="1rem"
                  fontFamily="Walsheim-Medium, sans-serif"
                >
                  INVESTMENTS
                </Typography>
                <TextField
                  InputProps={{
                    style: { height: 72, marginTop: 8, fontWeight: 'lighter' },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onChange={(e) => setInvestment(Number(e.target.value))}
                  type="number"
                  variant="outlined"
                  defaultValue={homeDetails.richrSavings}
                  value={investment?.toString()}
                />
              </div>
              <div>
                <Typography
                  variant="body2"
                  fontSize="1rem"
                  fontFamily="Walsheim-Medium, sans-serif"
                >
                  INVESTMENT PERIOD{' '}
                  <Typography
                    variant="body2"
                    fontSize="1rem"
                    color="#BDBDBD"
                    component="span"
                  >
                    (YEARS)
                  </Typography>
                </Typography>
                <TextField
                  InputProps={{
                    style: { height: 72, marginTop: 8, fontWeight: 'lighter' },
                  }}
                  onChange={(e) => setPeriod(+e.target.value)}
                  type="number"
                  variant="outlined"
                  defaultValue={period}
                />
              </div>
              <div>
                <Typography
                  variant="body2"
                  fontSize="1rem"
                  fontFamily="Walsheim-Medium, sans-serif"
                >
                  TOTAL
                </Typography>
                <Button
                  sx={{ my: 1 }}
                  fullWidth
                  variant="outlined"
                  size="large"
                  value="UNEMPLOYED"
                >
                  {usd.format(futureBalance)}
                </Button>
              </div>
            </ButtonsWrapper>
            <div>
              <Typography mt={3} mb={2}>
                YOUR <b>{usd.format(investment)}</b> IS ESTIMATED TO BE
              </Typography>
              <Typography
                variant="h6"
                fontSize="1.75rem"
                color="primary"
                component="span"
              >
                {usd.format(futureBalance)}
              </Typography>
              &nbsp;&nbsp;
              <Typography fontSize="1.75rem" component="span">
                in {period} Years
              </Typography>
              <Box mt={4}>
                <Button
                  sx={{ margin: 'auto', px: 8 }}
                  size="large"
                  startIcon={<CoinIconWhite />}
                  variant="contained"
                  onClick={approveHandler}
                >
                  Approve & Get Richr
                </Button>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Dashboard;
