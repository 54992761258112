import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { OnboardState } from 'store/OnboardState/OnboardState';
import { getSignUrl } from 'API/zoho';
import API from 'API';
import { ZOHO_DOCUMENT_STATUS } from 'utils/constants/zohoSign';
import Loader from './ZohoSignLoading';
import { ReactComponent as AgreeTermsImage } from '../assets/agree-terms.svg';
import InvestmentAdvisoryAgreementPdf from './assets/investment-advisory-agreement.pdf';
import CRSPdf from './assets/crs.pdf';
import ADVPart1 from './assets/form-adv-part-1.pdf';
import ADVPart2 from './assets/form-adv-part-2.pdf';

type FormType = {
  eSign: boolean;
};

function AgreeTerms() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { incrementStep } = OnboardState.useContainer();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await API.User.getZohoDocumentStatus().then((res) => {
        if (res.data.requestStatus === ZOHO_DOCUMENT_STATUS.COMPLETED) {
          incrementStep();
          navigate('/onboard/risk-profile/1');
        }
      });
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    // register,
    formState: { isValid },
    handleSubmit,
  } = useForm<FormType>({ mode: 'onChange' });

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const {
        data: { signUrl },
      } = await getSignUrl();
      window.open(signUrl, '_self');
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <AgreeTermsImage />
      </div>
      <Box my={2}>
        <Typography
          variant="h4"
          fontFamily="Walsheim-Light"
          display="inline-block"
        >
          Welcome to&nbsp;
        </Typography>
        <Typography variant="h4" display="inline-block">
          Richr Money
        </Typography>
      </Box>
      <Typography variant="h5" my={3}>
        Agreements
      </Typography>
      <Typography variant="body2" my={3}>
        By clicking &apos;Accept & Continue&apos;, I acknowledge that I am a US
        resident, 18 years or older, have thoroughly and completely read,
        understand, acknowledge the FORM CRS RELATIONSHIP SUMMARY. Further I
        have read, understood, and agree to be legally bound by Richr Money
        LLC&apos;s terms, conditions, and any and all disclaimers, or other
        disclosures, as made in the RICHR INVESTMENT ADVISORY AGREEMENT, and I
        agree to proceed with opening an account in my name with Richr Money
        LLC.
        <br />
        <br /> I agree that by clicking &apos;Accept & Continue&apos; shall be
        construed as and have the same legal equivalent of my manual/handwritten
        signature and will be relied upon as such by Richr Money LLC.
      </Typography>
      <a
        href={InvestmentAdvisoryAgreementPdf}
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms and Conditions
      </a>
      , {` `}
      <a href={CRSPdf} target="_blank" rel="noopener noreferrer">
        CRS
      </a>
      , {` `}
      <a href={ADVPart1} target="_blank" rel="noopener noreferrer">
        ADV Part 1
      </a>
      , and {` `}
      <a href={ADVPart2} target="_blank" rel="noopener noreferrer">
        ADV Part 2
      </a>
      <hr
        style={{
          color: '#e0e0e0',
          backgroundColor: '#e0e0e0',
          height: 0.5,
          width: '100%',
        }}
      />
      {/* <a */}
      {/*   href="https://apps.drivewealth.com/disclosures/?ria={NameOfYourRIA}&sweepType=bank" */}
      {/*   target="_blank" */}
      {/*   rel="noopener noreferrer" */}
      {/* > */}
      {/*   DriveWealth&apos;s Disclosures */}
      {/* </a> */}
      {/* , {` `} */}
      {/* <a */}
      {/*   href="https://legal.drivewealth.com/privacy-policy" */}
      {/*   target="_blank" */}
      {/*   rel="noopener noreferrer" */}
      {/* > */}
      {/*   DriveWealth&apos;s Privacy Policy */}
      {/* </a> */}
      {/* , and {` `} */}
      {/* <a */}
      {/*   href="https://apps.drivewealth.com/disclosures/?showOnlyTaxDoc=true&citizenship=USA " */}
      {/*   target="_blank" */}
      {/*   rel="noopener noreferrer" */}
      {/* > */}
      {/*   W9 Policy */}
      {/* </a> */}
      <Button
        disabled={!isValid}
        variant="contained"
        type="submit"
        size="large"
        fullWidth
        sx={{ mt: 3 }}
      >
        ACCEPT & CONTINUE
      </Button>
    </form>
  );
}

export default AgreeTerms;
