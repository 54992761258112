import { defineMessages } from 'react-intl';

export const scope = 'app.components.WealthSection';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Unlock a wealthier future with Richr Money',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Our three funds offer a range of investment options — Serenity, Harmony and Velocity. Richr Money matches home sellers with the fund that best fits their needs and risk tolerance. By selling with Richr Homes, you gain access to a truly unique opportunity to invest in your future...with ease.',
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: 'List your home for free with Richr today',
  },
  imageAltText: {
    id: `${scope}.imageAltText`,
    defaultMessage: 'exclusive section',
  },
});
