import React from 'react';
import { Button, Typography } from '@mui/material';
import { QuestionProps } from '../RiskProfile';

function Question2({ riskToleranceAnswers, onChoicePick }: QuestionProps) {
  return (
    <>
      <Typography variant="h6" mt={4} mb={1} fontFamily="Walsheim-Medium">
        What would you do if the stock market lost 10% of its value in one
        month?
      </Typography>
      <Button
        sx={{ my: 1 }}
        className={riskToleranceAnswers[1]?.choice.id === 4 ? 'active' : ''}
        fullWidth
        variant="outlined"
        size="large"
        onClick={() => onChoicePick(4)}
      >
        I would take advantage of low prices and &quot;buy the dip&quot;.
      </Button>
      <Button
        sx={{ my: 1 }}
        className={riskToleranceAnswers[1]?.choice.id === 5 ? 'active' : ''}
        fullWidth
        variant="outlined"
        size="large"
        onClick={() => onChoicePick(5)}
      >
        I would not change my investment strategy.
      </Button>
      <Button
        sx={{ my: 1 }}
        className={riskToleranceAnswers[1]?.choice.id === 6 ? 'active' : ''}
        fullWidth
        variant="outlined"
        size="large"
        onClick={() => onChoicePick(6)}
      >
        I would sell some or all of my investments.
      </Button>
    </>
  );
}

export default Question2;
