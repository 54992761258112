import styled from 'styled-components';

export const HowItWorksImage = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 494px;
    height: 525px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    img {
      width: 100%;
      height: auto;
      margin-bottom: 40px;
    }
  }
`;

export const HowItWorksWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Summary = styled.div`
  width: 48%;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;

    .how-it-works-button {
      letter-spacing: initial;
    }
  }
`;

export const StepList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Step = styled.li`
  display: flex;
  margin-top: 30px;
  align-items: center;

  h6 {
    font-size: 1.214rem;
    line-height: 1.35;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-top: 25px;

    h6 {
      font-size: 1.143rem;
      line-height: 1.31;
    }
  }
`;

export const OuterRing = styled.div`
  width: 65px;
  height: 65px;
  background: ${({ theme }) => `${theme.palette.primary.main}33`};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  flex-shrink: 0;

  h5 {
    width: 49px;
    height: 49px;
    background: ${({ theme }) => theme.palette.primary.main};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: ${({ theme }) => theme.palette.common.white};
    font-size: 1.571rem;
    line-height: 1.22;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 50px;
    height: 50px;
    margin-right: 15px;

    h5 {
      width: 37px;
      height: 37px;
      font-size: 1.429rem;
      font-weight: 700;
      line-height: 1.2;
    }
  }
`;
