import { Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import Header from 'components/Header/Header';
import { CTA_REDIRECT_URL } from 'constant';
import { ReactComponent as RightArrow } from 'assets/icons/right-arrow.svg';
import { WelcomeSectionWrapper, WelcomeContent, ButtonWrapper } from './styles';
import messages from './messages';

function WelcomeSection() {
  return (
    <WelcomeSectionWrapper>
      <Header />
      <WelcomeContent>
        <Typography variant="h1">
          <FormattedMessage {...messages.title} />
        </Typography>
        <Typography variant="h6">
          <FormattedMessage {...messages.description} />
        </Typography>
        <ButtonWrapper>
          <Button
            variant="contained"
            type="button"
            disableElevation
            endIcon={<RightArrow />}
            href={CTA_REDIRECT_URL}
          >
            <FormattedMessage {...messages.button} />
          </Button>
        </ButtonWrapper>
      </WelcomeContent>
    </WelcomeSectionWrapper>
  );
}

export default WelcomeSection;
