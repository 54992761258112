import styled from 'styled-components';
import pattern from './assets/pattern.svg';

export const ImageWrapper = styled.div`
  background: url(${pattern}) no-repeat center center / cover;
  background-color: ${({ theme }) => theme.palette.background.paper};

  .sell-smarter-section {
    padding: 90px 70px;

    ${(props) => props.theme.breakpoints.down('md')} {
      padding: 60px 20px;
    }
  }
`;

export const SellSmarterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    max-width: 625px;
  }

  p {
    max-width: 856px;
  }
`;
