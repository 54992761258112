import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Avatar,
  Box,
  IconButton,
  Typography,
  Menu,
  ListItemIcon,
  MenuItem,
  MenuList,
  Divider,
} from '@mui/material';
import {
  AccountBalance,
  Logout,
  CreditCard,
  // Person,
  AttachMoneyTwoTone,
} from '@mui/icons-material';
import { useNavigate, Link } from 'react-router-dom';
import { ReactComponent as Logo2 } from 'assets/icons/logo2.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppState } from 'store/app/AppState';
import LinkBankAccountModal from '../LinkBankAccountModal/LinkBankAccountModal';
import WithdrawFundsModal from '../WithdrawFundsModal/WithdrawFundsModal';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 32px 0;
`;

const CustomMenu = styled(Menu)`
  .MuiPaper-root {
    border-radius: 8px;
    border: 1px solid #00d4a1;
    margin-left: -85px;
    margin-top: 25px;
    padding: 0 10px;
  }
`;

function SignedInHeader() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const customMenuOpened = Boolean(anchorEl);
  const [isBankAccountModalOpen, setIsBankAccountModalOpen] = useState(false);
  const [isWithdrawFundsModalOpen, setIsWithdrawFundsModalOpen] =
    useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { appState, logOut } = AppState.useContainer();

  const logOutAction = () => {
    logOut();
    navigate('/');
  };

  return (
    <Wrapper>
      {appState.user.onboardingCompleted && (
        <LinkBankAccountModal
          isOpen={isBankAccountModalOpen}
          handleClose={() => setIsBankAccountModalOpen(false)}
        />
      )}
      {appState.user.onboardingCompleted && (
        <WithdrawFundsModal
          isOpen={isWithdrawFundsModalOpen}
          handleClose={() => setIsWithdrawFundsModalOpen(false)}
        />
      )}
      <Box pt={1}>
        <Link to="/">
          <Logo2 />
        </Link>
      </Box>
      <Box display="flex" alignItems="center">
        <Avatar
          alt="Person"
          sx={{
            width: 64,
            height: 64,
          }}
        >
          {appState.user.firstName[0]}
        </Avatar>
        <Box ml={3} mr={1}>
          <Typography variant="body2">Signed In as</Typography>
          <Typography variant="h6" lineHeight="unset">
            {appState.user.firstName} {appState.user.lastName}
          </Typography>
        </Box>
        <IconButton aria-label="expand" color="primary" onClick={handleClick}>
          <ExpandMoreIcon fontSize="large" />
        </IconButton>

        <CustomMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={customMenuOpened}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuList>
            <Typography color="#000000" fontSize="0.8rem" pl={2} mb={2}>
              ACCOUNT MENU
            </Typography>
            {appState.user.onboardingCompleted && (
              <>
                <MenuItem onClick={() => setIsBankAccountModalOpen(true)}>
                  <ListItemIcon>
                    <AccountBalance
                      fontSize="small"
                      sx={{ color: '#00d4a1' }}
                    />
                  </ListItemIcon>
                  <Typography color="#000000" fontSize="1rem">
                    Connect Bank Account
                  </Typography>
                </MenuItem>
                {/* <MenuItem onClick={() => setIsBankAccountModalOpen(true)}>
              <ListItemIcon>
                <Paid fontSize="small" sx={{ color: '#00d4a1' }} />
              </ListItemIcon>
              <Typography color="#000000" fontSize="1rem">
                Add Funds
              </Typography>
            </MenuItem> */}
                <MenuItem onClick={() => setIsWithdrawFundsModalOpen(true)}>
                  <ListItemIcon>
                    <CreditCard fontSize="small" sx={{ color: '#00d4a1' }} />
                  </ListItemIcon>
                  <Typography color="#000000" fontSize="1rem">
                    Withdraw Funds
                  </Typography>
                </MenuItem>

                <Link to="/trade">
                  <MenuItem>
                    <ListItemIcon>
                      <AttachMoneyTwoTone
                        fontSize="small"
                        sx={{ color: '#00d4a1' }}
                      />
                    </ListItemIcon>
                    <Typography color="#000000" fontSize="1rem">
                      Trade Confirmations
                    </Typography>
                  </MenuItem>
                </Link>

                <Link to="/monthly">
                  <MenuItem>
                    <ListItemIcon>
                      <AttachMoneyTwoTone
                        fontSize="small"
                        sx={{ color: '#00d4a1' }}
                      />
                    </ListItemIcon>
                    <Typography color="#000000" fontSize="1rem">
                      Monthly Statements
                    </Typography>
                  </MenuItem>
                </Link>

                <Link to="/tax">
                  <MenuItem>
                    <ListItemIcon>
                      <AttachMoneyTwoTone
                        fontSize="small"
                        sx={{ color: '#00d4a1' }}
                      />
                    </ListItemIcon>
                    <Typography color="#000000" fontSize="1rem">
                      Tax Documents
                    </Typography>
                  </MenuItem>
                </Link>

                <Link to="/transactions">
                  <MenuItem>
                    <ListItemIcon>
                      <AttachMoneyTwoTone
                        fontSize="small"
                        sx={{ color: '#00d4a1' }}
                      />
                    </ListItemIcon>
                    <Typography color="#000000" fontSize="1rem">
                      Transactions
                    </Typography>
                  </MenuItem>
                </Link>
              </>
            )}

            <Divider />

            {/* <MenuItem>
              <ListItemIcon>
                <Person fontSize="small" sx={{ color: '#ccc' }} />
              </ListItemIcon>
              <Typography color="#000000" fontSize="1rem">
                Account Settings
              </Typography>
            </MenuItem> */}
            <MenuItem onClick={logOutAction}>
              <ListItemIcon>
                <Logout fontSize="small" sx={{ color: '#ccc' }} />
              </ListItemIcon>
              <Typography color="#000000" fontSize="1rem">
                Log Out
              </Typography>
            </MenuItem>
          </MenuList>
        </CustomMenu>
      </Box>
    </Wrapper>
  );
}

export default SignedInHeader;
