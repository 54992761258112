import { defineMessages } from 'react-intl';

export const scope = 'app.components.WelcomeSection';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Welcome to Richr Money',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Maximize your home selling experience with Richr and start investing with Richr Money. Reinvest your earnings easily with our platform and watch your wealth grow. Join now and start building your financial future.',
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: 'Get started now',
  },
});
