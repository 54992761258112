import React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import API from 'API';
import { useNavigate } from 'react-router-dom';
import { APP_STATE, ONBOARD_STATE } from 'constant';

type FormType = {
  username: string;
};

function EmailForm() {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormType>();

  const onSubmit: SubmitHandler<FormType> = (data) => {
    API.User.reset(data.username).then((res) => {
      if (res?.data?.message) {
        localStorage.removeItem(ONBOARD_STATE);
        localStorage.removeItem(APP_STATE);
        localStorage.removeItem('rememberUser');
        localStorage.removeItem('user');
        navigate('/forgot-password/email-sent');
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" color="#000">
        Forgot Your Password?
      </Typography>
      <Typography variant="body2" color="#000" my={2}>
        Please provide your email below. We will send a link with the reset
        instructions shortly.
      </Typography>
      <TextField
        fullWidth
        label="Your username"
        autoComplete="username"
        type="text"
        margin="normal"
        {...register('username', {
          required: 'Please enter your username',
        })}
        error={Boolean(errors.username)}
        helperText={errors.username?.message}
      />
      <Button
        variant="contained"
        type="submit"
        size="large"
        fullWidth
        sx={{ mt: 3, mb: 2 }}
      >
        Continue
      </Button>
    </form>
  );
}

export default EmailForm;
