import Axios from '../axios';

export const Onboarding = {
  regulatoryQuestion: (data: any) =>
    Axios.post('/money-api/auth/regulatory-questions', data).then((res) => res),
  employmentInfo: (data: any) =>
    Axios.post('/money-api/auth/employment-info', data).then((res) => res),
  getRiskToleranceQuestions: () =>
    Axios.get('/money-api/risk-tolerance/questions').then((res) => res),
  calculateRiskTolerance: (data: any) =>
    Axios.post('/money-api/risk-tolerance/calculate', data).then((res) => res),
  getHomeDetails: () =>
    Axios.get('/money-api/auth/user-home-sale').then((res) => res),
  setSelectedAmount: (amount: any) =>
    Axios.post('/money-api/auth/set-selected-amount', {
      selectedAmount: amount,
    }).then((res) => res),
  addFinancialSituation: ({ annual, total }: any) =>
    Axios.post('/money-api/auth/financial-situation', {
      annualIncome: annual,
      totalNetWorth: total,
    }).then((res) => res),
  createDriveWealthUser: () => Axios.post('/money-api/drivewealth/create-user'),
  createDriveWealthAccount: () =>
    Axios.post('/money-api/drivewealth/create-account'),
  getJobRoles: () => Axios.get('/money-api/auth/jobs/roles').then((res) => res),
  getJobIndustries: () =>
    Axios.get('/money-api/auth/jobs/industries').then((res) => res),
};
