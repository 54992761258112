import React, { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { OnboardState } from 'store/OnboardState/OnboardState';
import { EMPLOYMENT_STATUS_KEY } from '../../../../constant';

type EmploymentStatusType =
  | 'EMPLOYED'
  | 'RETIRED'
  | 'STUDENT'
  | 'UNEMPLOYED'
  | 'SELF_EMPLOYED';

function Question1() {
  const { incrementStep } = OnboardState.useContainer();

  const [employmentStatus, setEmploymentStatus] =
    useState<EmploymentStatusType>(
      localStorage.getItem(EMPLOYMENT_STATUS_KEY) as EmploymentStatusType,
    );

  const navigate = useNavigate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    localStorage.setItem(EMPLOYMENT_STATUS_KEY, employmentStatus);
    incrementStep();
    navigate('2');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" mt={4} mb={1} fontFamily="Walsheim-Medium">
        What best describes your employment status?
      </Typography>
      <Button
        sx={{ my: 1 }}
        className={employmentStatus === 'EMPLOYED' ? 'active' : ''}
        fullWidth
        variant="outlined"
        size="large"
        value="EMPLOYED"
        onClick={() => setEmploymentStatus('EMPLOYED')}
      >
        Employed
      </Button>
      <Button
        className={employmentStatus === 'UNEMPLOYED' ? 'active' : ''}
        sx={{ my: 1 }}
        fullWidth
        variant="outlined"
        size="large"
        value="UNEMPLOYED"
        onClick={() => setEmploymentStatus('UNEMPLOYED')}
      >
        Unemployed
      </Button>
      <Button
        className={employmentStatus === 'SELF_EMPLOYED' ? 'active' : ''}
        sx={{ my: 1 }}
        fullWidth
        variant="outlined"
        size="large"
        value="SELF_EMPLOYED"
        onClick={() => setEmploymentStatus('SELF_EMPLOYED')}
      >
        Self-Employed
      </Button>
      <Button
        className={employmentStatus === 'RETIRED' ? 'active' : ''}
        sx={{ my: 1 }}
        fullWidth
        variant="outlined"
        size="large"
        value="RETIRED"
        onClick={() => setEmploymentStatus('RETIRED')}
      >
        Retired
      </Button>
      <Button
        className={employmentStatus === 'STUDENT' ? 'active' : ''}
        sx={{ my: 1 }}
        fullWidth
        variant="outlined"
        size="large"
        value="STUDENT"
        onClick={() => setEmploymentStatus('STUDENT')}
      >
        Student
      </Button>

      <Button
        disabled={employmentStatus === null}
        variant="contained"
        type="submit"
        size="large"
        sx={{ mt: 3, width: ['100%', '70%'] }}
      >
        Continue
      </Button>
    </form>
  );
}

export default Question1;
