import React, { useEffect, useState } from 'react';
import {
  Box,
  TableCell,
  Container,
  TableBody,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  Paper,
} from '@mui/material';
import SignedInHeader from 'components/Header/SignedInHeader';
import Footer from 'components/Footer/Footer';
import dayjs from 'dayjs';
import { getTransactionType } from 'utils/helpers/getTransactionType';
import { usd } from 'utils/helpers/currencyFormatter';
import API from 'API';

function Transactions() {
  const [transactions, setTransactions] = useState<any>([]);

  useEffect(() => {
    API.User.getTransactions().then((res) => {
      setTransactions(res.data);
    });
  }, []);

  return (
    <>
      <Box bgcolor="rgba(109,109,109,0.04)">
        <Container maxWidth="xl">
          <SignedInHeader />
        </Container>
      </Box>
      <Container maxWidth="xl" sx={{ mt: 11, mb: 12 }}>
        <h3>Transactions</h3>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Display Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(transactions || []).map((item: any) => (
                <TableRow
                  key={item?.finTranID}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {item?.comment}
                  </TableCell>
                  <TableCell>
                    {`Date: ${dayjs(item.tranWhen).format(
                      'MMM D, YYYY h:mm A'
                    )}`}
                  </TableCell>
                  <TableCell>
                    {`Type: ${getTransactionType(item.finTranTypeID)}`}
                  </TableCell>
                  <TableCell>
                    {`Amount: ${usd.format(item.tranAmount)}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Footer />
    </>
  );
}

export default Transactions;
