import styled from 'styled-components';

export const InputBox = styled.div`
  z-index: 9;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px;
  margin: 16px 0px 8px;
  border: 0px;
  vertical-align: top;
  width: 100%;

  .react-datepicker-wrapper {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.23);
    width: calc(100% - 2px);

    input {
      font: inherit;
      letter-spacing: inherit;
      border: 0px;
      box-sizing: content-box;
      background: none;
      height: 1.4375em;
      margin: 0px;
      -webkit-tap-highlight-color: transparent;
      display: block;
      min-width: 0px;
      width: 100%;
      animation-name: mui-auto-fill-cancel;
      animation-duration: 10ms;
      padding: 16.5px 14px;
      color: #00152a;

      :placeholder {
        color: #00152a;
        opacity: 1; /* Firefox */
      }
    }

    &:hover {
      border-color: #00152a;
    }
  }

  &.has-error {
    .react-datepicker-wrapper {
      border-color: #d40000;

      input {
        color: #d40000;
        :placeholder {
          color: #d40000;
          opacity: 1; /* Firefox */
        }
      }
    }
  }
`;
