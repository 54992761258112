export const getTransactionType = (type: string) => {
  switch (type) {
    case 'CSR':
      return 'Deposit';

    case 'CSD':
      return 'Withdraw';

    case 'COMM':
      return 'Commission';

    case 'SSAL':
      return 'Stock Sold';

    case 'FEE':
      return 'Withdrawal Fee';

    default:
      return '';
  }
};
