import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';

function RecoverySuccess() {
  const navigate = useNavigate();

  return (
    <div>
      <Typography variant="h6">Success!</Typography>
      <Typography variant="body2" mt={2} mb={2}>
        Your password has been successfully changed. Now you can login to your
        account by using it.
      </Typography>
      <Button
        variant="contained"
        type="submit"
        size="large"
        fullWidth
        sx={{ mt: 3, mb: 2 }}
        onClick={() => navigate('/signin')}
      >
        GO TO LOGIN PAGE
      </Button>
    </div>
  );
}

export default RecoverySuccess;
