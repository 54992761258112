import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

export function initializeSentry() {
  Sentry.init({
    dsn:
      process.env.REACT_APP_SENTRY_DNS ||
      'https://a78efd4f529d4e42943dbdb8b07297f6@o4504712448966656.ingest.sentry.io/4504790341517312',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT || 'development',
  });
}
