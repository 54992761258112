import { defineMessages } from 'react-intl';

export const scope = 'app.components.HowItWorks';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Ready to start investing with Richr Money?',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'If you{rsquo}ve listed a home with Richr you already have exclusive access to this unique opportunity. Not yet a seller? Head over to {richr_link} to learn more about how you can sell your home faster, save more and invest in your future with Richr today.',
  },
  richrHomepage: {
    id: `${scope}.richrHomepage`,
    defaultMessage: 'Richr.com',
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: 'Sell Your Home with Richr Homes',
  },
  details: {
    id: `${scope}.details`,
    defaultMessage:
      'Learn more about Richr Money and our investment offerings on our {blogLink}. Stay up to date on the latest financial news and trends, and get expert insights on how to make the most of your investments.',
  },
  contactUs: {
    id: `${scope}.contactUs`,
    defaultMessage:
      'Have a question? {contactLink} and we{rsquo}ll be happy to help',
  },
  blogLinkText: {
    id: `${scope}.blogLinkText`,
    defaultMessage: 'blog',
  },
  contactUsLinkText: {
    id: `${scope}.contactUsLinkText`,
    defaultMessage: 'Contact our team',
  },
  copyright: {
    id: `${scope}.copyright`,
    defaultMessage: '© Copyright 2023. Richr Inc.',
  },
  allRightsReserved: {
    id: `${scope}.termsAndPrivacy`,
    defaultMessage: 'ALL RIGHTS RESERVED.',
  },
  termsAndPrivacy: {
    id: `${scope}.termsAndPrivacy`,
    defaultMessage: 'Terms & Privacy',
  },
  linkOne: {
    id: `${scope}.linkOne`,
    defaultMessage: 'Terms of Service',
  },
  linkTwo: {
    id: `${scope}.lineTwo`,
    defaultMessage: 'Privacy Policy',
  },
  linkThree: {
    id: `${scope}.linkThree`,
    defaultMessage: 'Affiliated Business Disclosure',
  },
  legal: {
    id: `${scope}.legal`,
    defaultMessage:
      'All information provided is deemed reliable but is not guaranteed and should be independently verified. You should consult your own professionals for financial, legal, or tax advice and conduct your own due diligence. Brokerage services are provided by Richr Homes, FL License # 1060165. Services of Richr{apos}s affiliates, Richr Insurance and Richr Title, are optional and can be used in combination or separately.',
  },
});
