import styled from 'styled-components';

export const Foot = styled.div`
  .MuiTypography-root {
    color: #fff;
  }

  > div:last-child {
    padding: 28px;
    background-color: #232323;

    .MuiTypography-root {
      font-size: 0.75rem;
    }
  }

  > div:first-child {
    padding: 100px 0;
    background-color: #000000;
  }
`;
