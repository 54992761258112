import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppState } from 'store/app/AppState';
import { APP_STATE } from 'constant';
import ConfirmIdentity from 'views/Signup/ConfirmIdentity/ConfirmIdentity';
import SignupForm from 'views/Signup/SignupForm/SignupForm';
import RiskProfile from 'views/Onboard/RiskProfile/RiskProfile';
import EmploymentInfo from 'views/Onboard/EmploymentInfo/EmploymentInfo';
import Essentials from 'views/Onboard/Essentials/Essentials';
import InvestmentGoals from 'views/Onboard/InvestmentGoals/InvestmentGoals';
import HomePage from 'views/HomePage/HomePage';
import Signup from 'views/Signup/Signup';
import SignIn from 'views/SignIn/SignIn';
import Onboard from 'views/Onboard/Onboard';
import Info from 'views/Signup/Info/Info';
import MessageBox from 'components/MessageBox/MessageBox';
import ForgotPassword from 'views/ForgotPassword/ForgotPassword';
import EmailForm from 'views/ForgotPassword/EmailForm/EmailForm';
import RecoverySuccess from 'views/ForgotPassword/RecoverySuccess/RecoverySuccess';
import EmailSuccess from 'views/ForgotPassword/EmailSuccess/EmailSuccess';
import PasswordRecoveryForm from 'views/ForgotPassword/PasswordRecoveryForm/PasswordRecoveryForm';
import AgreeTerms from 'views/Onboard/AgreeTerms/AgreeTerms';
import Dashboard from 'views/Dashboard/Dashboard';
import RecommendedInvestment from 'views/Onboard/RecommendedInvestments/RecommendedInvestment';
import TradeHistory from 'views/TradeHistory/TradeHistory';
import Tax from 'views/Tax/Tax';
import Transactions from 'views/Transactions/Transactions';
import Monthly from 'views/Monthly/Monthly';
import Sorry from 'views/Onboard/Sorry';
import FinancialSitutation from 'views/Onboard/FinancialSituation/FinancialSitutation';
import ConfirmInvestments from '../views/Onboard/ConfirmInvestments/ConfirmInvestments';

export function AppRoutes() {
  const { appState } = AppState.useContainer();

  const accessToken =
    appState.accessToken ||
    JSON.parse(localStorage.getItem(APP_STATE) || '{}').accessToken;
  const { user } = JSON.parse(localStorage.getItem(APP_STATE) || '{}');

  if (!accessToken) {
    return (
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="create-password" element={<PasswordRecoveryForm />} />
        <Route path="forgot-password" element={<ForgotPassword />}>
          <Route index element={<EmailForm />} />
          <Route path="email-sent" element={<EmailSuccess />} />
          <Route path="password-recovery" element={<PasswordRecoveryForm />} />
          <Route path="success" element={<RecoverySuccess />} />
        </Route>

        <Route path="signup" element={<Signup />}>
          <Route path=":inviteCode" element={<SignupForm />} />
          <Route path="confirm-identity" element={<ConfirmIdentity />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }

  // need add onboardingCompleted && appState.user.onboardingCompleted
  if (accessToken && user.onboardingCompleted) {
    return (
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="myhome" element={<Dashboard />} />
        <Route path="trade" element={<TradeHistory />} />
        <Route path="monthly" element={<Monthly />} />
        <Route path="tax" element={<Tax />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="*" element={<Navigate to="myhome" />} />
      </Routes>
    );
  }

  if (accessToken && !user.onboardingCompleted) {
    return (
      <Routes>
        {/* if are going to add/edit a route please make sure you check Onboard.tsx and edit/add steps accordingly. */}
        <Route path="onboard" element={<Onboard />}>
          <Route path="info" element={<Info />} />
          <Route
            path="/onboard/financial-situation"
            element={<FinancialSitutation />}
          />
          <Route path="essentials" element={<Essentials />} />
          <Route path="employment-info" element={<EmploymentInfo />} />
          <Route path="employment-info/:step" element={<EmploymentInfo />} />
          <Route path="agree-terms" element={<AgreeTerms />} />
          <Route path="risk-profile/:question" element={<RiskProfile />} />
          <Route path="risk-profile/*" element={<Navigate to="1" replace />} />
          <Route
            path="recommended-investments"
            element={<RecommendedInvestment />}
          />
          <Route path="investment-goals" element={<InvestmentGoals />} />
          <Route path="confirm-investments" element={<ConfirmInvestments />} />
          <Route
            path="success"
            element={
              <MessageBox
                title="Congratulations!"
                body="Congratulations you have now successfully created your account for
          Richr Money"
              />
            }
          />
          <Route path="sorry" element={<Sorry />} />
        </Route>
        <Route path="*" element={<Navigate to="onboard" />} />
      </Routes>
    );
  }

  return null;
}
