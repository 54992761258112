import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  FormHelperText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { OnboardState } from 'store/OnboardState/OnboardState';
import API from 'API';
import { EMPLOYMENT_STATUS_KEY } from '../../../../constant';

type FormInputType = {
  employerName: string;
  jobIndustry: string;
  jobDescription: string;
};

type EmploymentStatusType =
  | 'EMPLOYED'
  | 'RETIRED'
  | 'STUDENT'
  | 'UNEMPLOYED'
  | 'SELF_EMPLOYED';

function Question2() {
  const { incrementStep } = OnboardState.useContainer();
  const [employmentStatus] = useState<EmploymentStatusType>(
    localStorage.getItem(EMPLOYMENT_STATUS_KEY) as EmploymentStatusType,
  );
  const [jobIndustries, setJobIndustries] = useState<any[]>([]);
  const [jobRoles, setJobRoles] = useState<any[]>([]);

  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormInputType>({ reValidateMode: 'onChange' });
  const onSubmit: SubmitHandler<FormInputType> = async (data) => {
    await API.Onboarding.employmentInfo({
      employmentStatus,
      employerCompany: data.employerName,
      employmentType: data.jobDescription,
      employmentPosition: data.jobIndustry,
    });
    localStorage.removeItem(EMPLOYMENT_STATUS_KEY);
    incrementStep();
    navigate('/onboard/risk-profile/1');
  };

  const [jobIndustry, setJobIndustry] = React.useState('');
  const [description, setDescription] = React.useState('');

  const handleIndustryChange = (event: SelectChangeEvent) => {
    setJobIndustry(event.target.value);
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    API.Onboarding.getJobIndustries().then((res) => {
      setJobIndustries(res.data);
    });

    API.Onboarding.getJobRoles().then((res) => {
      setJobRoles(res.data);
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        label="Employer Name"
        margin="normal"
        {...register('employerName', {
          required:
            employmentStatus === 'UNEMPLOYED' ||
            employmentStatus === 'STUDENT' ||
            employmentStatus === 'SELF_EMPLOYED'
              ? false
              : 'Please enter the employer name',
        })}
        error={Boolean(errors.employerName)}
        helperText={errors.employerName?.message}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="Description-select-label">Job Description</InputLabel>
        <Select
          labelId="Description-select-label"
          id="Description-select"
          value={description}
          label="Job Description"
          {...register('jobIndustry', {
            required:
              employmentStatus === 'UNEMPLOYED' ||
              employmentStatus === 'STUDENT'
                ? false
                : 'Please select occupation job industry',
          })}
          error={Boolean(errors.jobIndustry)}
          onChange={handleRoleChange}
        >
          {(jobRoles || []).map(
            ({ position, description: roleDescription }) => (
              <MenuItem key={position} value={position}>
                {roleDescription}
              </MenuItem>
            ),
          )}
        </Select>
        {errors.jobIndustry?.message && (
          <FormHelperText error>{errors.jobIndustry?.message}</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="job-industry-select-label">Job Industry</InputLabel>
        <Select
          labelId="job-industry-select-label"
          id="job-industry-select"
          value={jobIndustry}
          label="Job Industry"
          {...register('jobDescription', {
            required:
              employmentStatus === 'UNEMPLOYED' ||
              employmentStatus === 'STUDENT'
                ? false
                : 'Please select job industry',
          })}
          error={Boolean(errors.jobDescription)}
          onChange={handleIndustryChange}
        >
          {(jobIndustries || []).map(
            ({ industry, description: jobDescription }) => (
              <MenuItem key={industry} value={industry}>
                {jobDescription}
              </MenuItem>
            ),
          )}
        </Select>
        {errors.jobDescription?.message && (
          <FormHelperText error>
            {errors.jobDescription?.message}
          </FormHelperText>
        )}
      </FormControl>
      <Button
        variant="contained"
        type="submit"
        size="large"
        fullWidth
        sx={{ mt: 3 }}
      >
        Continue
      </Button>
    </form>
  );
}

export default Question2;
