import styled from 'styled-components';

export const FeatureList = styled.ul`
  margin: 25px 0 0 0;
  padding: 0;
  list-style: none;
`;

export const FeatureListItem = styled.li`
  margin-top: 10px;
  font-size: 1.214rem;
  font-weight: 600;
  line-height: 1.35;

  &::before {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 50%;
    content: '';
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 2px;
    height: 10px;
    width: 10px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    font-weight: 500;

    &::before {
      width: 8px;
      height: 8px;
    }
  }
`;

export const ExclusiveSectionImage = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 485px;
    height: 385px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    img {
      width: 335px;
      height: 266px;
      margin-top: 40px;
    }
  }
`;

export const ExclusiveSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Summary = styled.div`
  width: 49%;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
`;
