import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Container, styled } from '@mui/material';
import { ReactComponent as Logo2 } from 'assets/icons/logo2.svg';
import { ReactComponent as Image } from './assets/fg-image.svg';

const MuiContainer = styled(Container)`
  text-align: center;
  padding-top: 36px;
`;

function ForgotPassword() {
  return (
    <>
      <Helmet>
        <style>
          {`
              body {
                  background-color: rgba(109,109,109,0.04);
              }
           `}
        </style>
      </Helmet>
      <MuiContainer maxWidth="sm">
        <Link to="/">
          <Logo2 />
        </Link>
        <Box mt={16} mb={3}>
          <Image />
        </Box>
        <Outlet />
      </MuiContainer>
    </>
  );
}

export default ForgotPassword;
