import React, { MouseEventHandler } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as MoneyImage } from 'assets/icons/money.svg';

type NoAccountProps = {
  ready: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

function NoAccount({ ready, onClick }: NoAccountProps) {
  return (
    <>
      <Box my={3}>
        <MoneyImage />
      </Box>
      <Typography variant="h4" fontSize="2.25rem">
        No Bank Account Linked
      </Typography>
      <Typography variant="body2" mt={3} mb={4}>
        <Typography
          variant="body2"
          fontFamily="Walsheim-Bold, sans-serif"
          component="span"
        >
          Link your bank account{' '}
        </Typography>
        to start investing.
      </Typography>
      <Button
        disabled={!ready}
        sx={{ width: '379px', fontSize: '1.3rem' }}
        variant="contained"
        onClick={onClick}
      >
        Continue
      </Button>
    </>
  );
}

export default NoAccount;
