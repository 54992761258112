import { Button, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { SectionLayout } from 'components/layouts/sectionLayout';
import { CTA_REDIRECT_URL } from 'constant';
import {
  LearnMoreSectionWrapper,
  LearnMoreSectionImage,
  Summary,
} from './styles';
import learnMore from './assets/learnMore.jpg';
import messages from './messages';

function LearnMoreSection() {
  const intl = useIntl();

  return (
    <LearnMoreSectionWrapper>
      <SectionLayout className="learn-more-section">
        <Summary>
          <Typography variant="h1">
            <FormattedMessage {...messages.title} />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage
              {...messages.description}
              values={{ rsquo: <>&rsquo;</> }}
            />
          </Typography>
          <div>
            <Button
              variant="contained"
              disableElevation
              href={CTA_REDIRECT_URL}
              className="learn-more-button"
            >
              <FormattedMessage {...messages.button} />
            </Button>
          </div>
        </Summary>
      </SectionLayout>
      <LearnMoreSectionImage>
        <img
          src={learnMore}
          alt={intl.formatMessage({ ...messages.imageAltText })}
        />
      </LearnMoreSectionImage>
    </LearnMoreSectionWrapper>
  );
}

export default LearnMoreSection;
