import { defineMessages } from 'react-intl';

export const scope = 'app.components.SellSmarter';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Don{rsquo}t just sell...sell smarter with Richr',
  },
  description1: {
    id: `${scope}.description1`,
    defaultMessage:
      'If you{rsquo}re a home seller you may be asking “what would a savvy investor do with the proceeds after they sell their home?”. The answer: they invest in their future.',
  },
  description2: {
    id: `${scope}.description2`,
    defaultMessage:
      'Richr Money makes it simple for home sellers to sell like a pro and maximize the value of their 2% cash back. By choosing Richr, and taking advantage of exclusive access to Richr Money, you can sell your home faster, save more AND invest in your future with one trusted partner.',
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: 'Sell Your Home with Richr Homes',
  },
});
