import styled from 'styled-components';

export const WealthContainer = styled.div`
  ${(props) => props.theme.breakpoints.down('md')} {
    background: ${({ theme }) =>
      `linear-gradient(0deg, ${theme.palette.success.light} 86%, ${theme.palette.common.white} 14%)`};
  }
`;

export const WealthImage = styled.div`
  display: flex;
  align-items: center;
  width: 50%;

  img {
    width: 524px;
    height: 387px;
  }

  @media (max-width: 1150px) {
    img {
      width: 374px;
      height: 276px;
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;

    img {
      width: 100%;
      height: auto;
      margin-bottom: 40px;
    }
  }
`;

export const WealthWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Summary = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) => props.theme.breakpoints.down('md')} {
    text-align: center;
    width: 100%;
  }
`;
