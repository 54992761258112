import { Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { SectionLayout } from 'components/layouts/sectionLayout';
import messages from 'components/SellSmarterSection/messages';
import { CTA_REDIRECT_URL } from 'constant';
import { SellSmarterWrapper, ImageWrapper } from './styles';

function SellSmarterSection() {
  return (
    <ImageWrapper>
      <SectionLayout className="sell-smarter-section">
        <SellSmarterWrapper>
          <Typography variant="h1">
            <FormattedMessage
              {...messages.title}
              values={{ rsquo: <>&rsquo;</> }}
            />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage
              {...messages.description1}
              values={{ rsquo: <>&rsquo;</> }}
            />
            <br />
            <br />
            <FormattedMessage {...messages.description2} />
          </Typography>
          <Button variant="contained" disableElevation href={CTA_REDIRECT_URL}>
            <FormattedMessage {...messages.button} />
          </Button>
        </SellSmarterWrapper>
      </SectionLayout>
    </ImageWrapper>
  );
}

export default SellSmarterSection;
