import React from 'react';
import { Button, Typography } from '@mui/material';
import { QuestionProps } from '../RiskProfile';

function Question3({ riskToleranceAnswers, onChoicePick }: QuestionProps) {
  return (
    <>
      <Typography variant="h6" mt={4} mb={1} fontFamily="Walsheim-Medium">
        When do you plan to access some or all of your investments?
      </Typography>
      <Button
        sx={{ my: 1 }}
        className={riskToleranceAnswers[2]?.choice.id === 7 ? 'active' : ''}
        fullWidth
        variant="outlined"
        size="large"
        onClick={() => onChoicePick(7)}
      >
        I plan to access my investments in the next twelve months.
      </Button>
      <Button
        sx={{ my: 1 }}
        className={riskToleranceAnswers[2]?.choice.id === 8 ? 'active' : ''}
        fullWidth
        variant="outlined"
        size="large"
        onClick={() => onChoicePick(8)}
      >
        I plan to access my investments within 5 years.
      </Button>
      <Button
        sx={{ my: 1 }}
        className={riskToleranceAnswers[2]?.choice.id === 9 ? 'active' : ''}
        fullWidth
        variant="outlined"
        size="large"
        onClick={() => onChoicePick(9)}
      >
        I plan to access my investments within 10 years or longer.
      </Button>
    </>
  );
}

export default Question3;
