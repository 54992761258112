import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ReactComponent as EmploymentImage } from '../assets/employment.svg';
import Question2 from './Question2/Question2';
import Question1 from './Question1/Question1';

function EmploymentInfo() {
  const { step } = useParams();

  let content;
  switch (step) {
    case '2':
      content = <Question2 />;
      break;
    default:
      content = <Question1 />;
  }

  return (
    <>
      <div>
        <EmploymentImage />
      </div>
      <Box my={2}>
        <Typography variant="h4" display="inline-block">
          Employment&nbsp;
        </Typography>
        <Typography
          variant="h4"
          fontFamily="Walsheim-Light"
          display="inline-block"
        >
          Information
        </Typography>
      </Box>
      {content}
    </>
  );
}

export default EmploymentInfo;
