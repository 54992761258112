import React, { MouseEventHandler } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as MoneyImage } from 'assets/icons/money.svg';
import { PlaidAccount } from 'react-plaid-link';

type SucceedAccountProps = {
  account: PlaidAccount;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

function SucceedAccount({ account, onClick }: SucceedAccountProps) {
  return (
    <>
      <Box my={3}>
        <MoneyImage />
      </Box>
      <Typography variant="h4" fontSize="2.25rem">
        Bank Account Successfully Linked.
      </Typography>
      <Typography variant="body2" mt={3} mb={4}>
        <Typography
          variant="body2"
          fontFamily="Walsheim-Bold, sans-serif"
          component="span"
        >
          Bank account {account.id}{' '}
        </Typography>
        has been successfully linked to your Richr Money account.
      </Typography>
      <Button
        sx={{ width: '379px', fontSize: '1.3rem' }}
        variant="contained"
        onClick={onClick}
      >
        Done
      </Button>
    </>
  );
}

export default SucceedAccount;
