import styled from 'styled-components';

import coverImage from './assets/cover-image.jpg';
import coverImageMobile from './assets/cover-image-mobile.jpg';

export const WelcomeSectionWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.background.default}
    url(${coverImage}) no-repeat center center / cover;

  ${(props) => props.theme.breakpoints.down('md')} {
    background: ${({ theme }) => theme.palette.background.default}
      url(${coverImageMobile}) no-repeat center bottom / cover;
  }
`;

export const WelcomeContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  width: 50%;
  padding: 0 70px;
  margin-bottom: 150px;

  h1 {
    font-size: 3.929rem;
    line-height: 1.21;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.palette.common.black};
    letter-spacing: initial;
  }

  h6 {
    font-size: 1.429rem;
    line-height: 1.2;
    margin-bottom: 30px;
    color: ${({ theme }) => theme.palette.secondary.main};
    width: 90%;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 0 20px;
    width: initial;
    margin-bottom: 170px;

    h1 {
      font-size: 2.857rem;
      line-height: 1.2;
      margin-bottom: 15px;
    }

    h6 {
      font-size: 1.214rem;
      line-height: 1.35;
      margin-bottom: 25px;
      width: initial;
    }
  }
`;

export const ButtonWrapper = styled.div`
  a {
    font-size: 1.214rem;
    line-height: 1.23;
    padding: 17px 34px;

    ${(props) => props.theme.breakpoints.down('md')} {
      padding: 10px 22px;
    }
  }
`;
