import React from 'react';
import { Button, Typography } from '@mui/material';
import { ReactComponent as XIcon } from 'assets/icons/x.svg';
import { PlaidAccount } from 'react-plaid-link';
import BankIcon from 'assets/icons/bank.svg';
import { Content, AccountWrapper } from './Account.styles';

type AccountProps = {
  account: PlaidAccount;
  onDelete: (accountId: string) => void;
};

function Account({ account, onDelete }: AccountProps) {
  return (
    <AccountWrapper>
      <div>
        <img src={BankIcon} alt="bank" />
      </div>
      <Content>
        <Typography
          variant="h6"
          fontFamily="Walsheim-Medium, sans-serif"
          lineHeight="unset"
        >
          Linked Bank Account
        </Typography>
        <div className="row">
          <Typography variant="body2">Account No.</Typography>
          <Typography variant-="body2" fontFamily="Walsheim-Medium, sans-serif">
            {account.id}
          </Typography>
        </div>
        <Button
          onClick={() => onDelete(account.id)}
          sx={{ fontSize: '1.125rem', color: '#a30000', ml: -3 }}
          startIcon={<XIcon />}
        >
          Unlink and remove account
        </Button>
      </Content>
    </AccountWrapper>
  );
}

export default Account;
