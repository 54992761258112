import styled from 'styled-components';

export const AccountWrapper = styled.div`
  border: 1px solid #e7e1e1;
  padding: 20px;
  display: flex;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;

  .row {
    margin-top: 12px;
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
  }
`;
