import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  button {
    font-weight: 500;
    font-size: 1.214rem;
    line-height: 1.23;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 70px 0;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 20px;
  }
`;

export const LogoWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('md')} {
    svg {
      width: 133px;
      height: 22px;
    }
  }
`;
