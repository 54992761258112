import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SignedInHeader from "components/Header/SignedInHeader";
import Footer from "components/Footer/Footer";
import { OnboardState } from "store/OnboardState/OnboardState";
import { AppState } from "store/app/AppState";
import { Store } from "react-notifications-component";
import API from "API";
import { usd } from "utils/helpers/currencyFormatter";
import { ONBOARD_STATE } from 'constant';
import {
  AmountInfo,
  InfoBox,
  Investment,
  CoinIconWhite,
  LocationImage,
  PriceUnderlineImg,
  ButtonsGroup,
} from "./styles";
import locationImage from "../RecommendedInvestments/assets/house.jpeg";
import priceLineImage from "../RecommendedInvestments/assets/priceLine.png";
// import { locale } from 'dayjs';

const initialHomeDetails = {
  city: "",
  closingDate: null,
  image: "",
  richrSavings: 0,
  salePrice: 0,
  state: "",
  streetNumber: "",
  zipCode: "",
  dealTitle: "",
  unparsedAddress: "",
};

function ClaimSavings() {
  const { setCalculatorData, onboard } = OnboardState.useContainer();
  const { setOnboardComplete } = AppState.useContainer();
  const [investment, setInvestment] = useState<number>(
    onboard.calculatorData?.investmentAmount,
  );
  // const [period] = useState(onboard.calculatorData?.investmentPeriod);
  const [interestRate] = useState(onboard.calculatorData?.interestRate);
  const [homeDetails, setHomeDetails] = useState(initialHomeDetails);
  const [investPercent, setInvestPercent] = useState<number>(25);
  const [, setConfirmedInvest] = useState(
    onboard.calculatorData?.futureBalance,
  );
  const [isCustomInputActive, setIsCostumInputActive] = useState<boolean>(true);
  const [futureInvest, setFutureInvest] = useState<number>(1);
  // const numberOfTimes = onboard.calculatorData?.numberOfTimesInMonth || 12;

  useEffect(() => {
    API.Onboarding.getHomeDetails().then((res) => {
      const { data } = res;
      setHomeDetails(data);
    });
  }, []);

  useEffect(() => {
    setFutureInvest(
      // eslint-disable-next-line no-unsafe-optional-chaining
      (onboard.calculatorData?.homeSalePrice * investPercent) / 100 +
        investment,
    );
  }, [onboard.calculatorData?.homeSalePrice, investPercent, investment]);

  useEffect(() => {
    setInvestment(onboard.calculatorData?.investmentAmount);
  }, [onboard.calculatorData?.investmentAmount]);

  const futureBalance = useMemo(
    () => (investPercent / 100) * (onboard?.calculatorData?.homeSalePrice || 1),
    [investPercent, onboard.calculatorData.homeSalePrice],
  );

  const investPercentHandler = (e: { target: { value: string | number } }) => {
    const val: number = +e.target.value;
    if (val < 1) {
      setInvestPercent(1);
      return;
    }
    if (val > 100) {
      setInvestPercent(100);
      return;
    }
    setInvestPercent(val);
  };

  const fixedBtnsHandler = (number: number) => {
    setIsCostumInputActive(true);
    setInvestPercent(number);
  };

  const confirmHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    API.Onboarding.setSelectedAmount(
      futureInvest ? Math.round(futureInvest) : 30,
    )
      .then((res) => {
        if (res.status === 200) {
          setConfirmedInvest(futureBalance);
          setInvestPercent(0);
          setCalculatorData({
            investmentAmount: futureInvest,
            interestRate,
            futureBalance: Math.round(futureBalance),
          });
          Store.addNotification({
            title: "Successfully send!",
            type: "success",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
          API.User.sendOnboardComplete().then(() => {
            localStorage.removeItem(ONBOARD_STATE);
            setOnboardComplete();
          });
        }
      })
      .catch((err) => {
        Store.addNotification({
          title: "Error!",
          message: err,
          type: "danger",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });
  };

  const proceedWithoutInvestment = () => {
    API.Onboarding.setSelectedAmount(
      futureInvest ? Math.round(onboard.calculatorData.investmentAmount) : 30,
    ).then(() => {
      API.User.sendOnboardComplete().then(() => {
        localStorage.removeItem(ONBOARD_STATE);
        setOnboardComplete();
      });
    });
  };

  return (
    <>
      <Box bgcolor="rgba(109,109,109,0.04)">
        <Container maxWidth="xl">
          <SignedInHeader />
        </Container>
      </Box>
      <Container maxWidth="xl" sx={{ mt: 11, mb: 12 }}>
        <Grid container columnSpacing={19} rowSpacing={12}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h3" fontSize="3rem">
                Do you want to invest more?
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <InfoBox>
              <LocationImage
                src={homeDetails.image ? homeDetails.image : locationImage}
                alt="location"
              />
              <div>
                <Typography
                  variant="h4"
                  fontSize="2.25rem"
                  fontFamily="Walsheim-Medium, sans-serif"
                >
                  {homeDetails.unparsedAddress}
                </Typography>
                <Typography fontFamily="Walsheim-Medium, sans-serif">
                  {homeDetails.closingDate}
                </Typography>
              </div>
              <AmountInfo>
                <div>
                  <Typography
                    fontSize="1rem"
                    fontFamily="Walsheim-Medium, sans-serif"
                    mb={1}
                  >
                    Sale Price
                  </Typography>
                  <Typography variant="h6">
                    {usd.format(homeDetails.salePrice)}
                  </Typography>
                </div>
                <div>
                  <Typography
                    fontSize="1rem"
                    fontFamily="Walsheim-Medium, sans-serif"
                    mb={1}
                  >
                    RICHR SAVINGS
                  </Typography>
                  <Typography variant="h6">
                    {usd.format(homeDetails.richrSavings)}
                  </Typography>
                </div>
                <div>
                  <Typography
                    fontSize="1rem"
                    fontFamily="Walsheim-Medium, sans-serif"
                    mb={1}
                  >
                    NET PROCEEDS
                  </Typography>
                  <Typography color="primary.main" variant="h6">
                    {usd.format(homeDetails.salePrice)}
                  </Typography>
                </div>
              </AmountInfo>
            </InfoBox>
          </Grid>
          <Grid item xs={7}>
            <Investment onSubmit={confirmHandler}>
              <Typography fontSize="2.9rem" component="span">
                You are investing
                <Typography
                  variant="h4"
                  fontSize="2.9rem"
                  sx={{
                    position: "relative",
                    marginLeft: "10px",
                    zIndex: "1",
                  }}
                  component="span"
                >
                  {usd.format(onboard?.calculatorData?.investmentAmount)} from{" "}
                  {usd.format(onboard?.calculatorData?.homeSalePrice)}
                  <PriceUnderlineImg src={priceLineImage} />
                </Typography>
              </Typography>
              <Box fontSize="1.35rem" mt={3} mb={2}>
                Your Richr Account will be automatically funded when at closing.
              </Box>
              <Typography
                fontSize="1.35rem"
                variant="h6"
                component="span"
                mt={3}
                mb={2}
              >
                Want to grow your wealth
                <Typography
                  fontWeight="300"
                  sx={{ marginLeft: "5px" }}
                  component="span"
                >
                  even more?
                </Typography>
              </Typography>
              <Typography
                variant="h6"
                fontSize="1.15rem"
                mt={5}
                mb={2}
                component="div"
              >
                INVEST <span style={{ color: "#BDBDBD" }}>(% OF PROCEEDS)</span>
              </Typography>
              <ButtonsGroup>
                <Button
                  sx={{ my: 1 }}
                  fullWidth
                  variant="outlined"
                  size="large"
                  value="25"
                  onClick={() => fixedBtnsHandler(25)}
                >
                  25%
                </Button>
                <Button
                  sx={{ my: 1 }}
                  fullWidth
                  variant="outlined"
                  size="large"
                  value="30"
                  onClick={() => fixedBtnsHandler(30)}
                >
                  30%
                </Button>
                <Button
                  sx={{ my: 1 }}
                  fullWidth
                  variant="outlined"
                  size="large"
                  value="35"
                  onClick={() => fixedBtnsHandler(35)}
                >
                  35%
                </Button>
                <Button
                  sx={{ my: 1 }}
                  fullWidth
                  variant="outlined"
                  size="large"
                  value="50"
                  onClick={() => fixedBtnsHandler(50)}
                >
                  50%
                </Button>
                {isCustomInputActive ? (
                  <TextField
                    InputProps={{
                      style: { fontWeight: 200, height: 96, width: 106 },
                      inputProps: { min: 1, max: 100 },
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    autoFocus
                    label="Custom"
                    type="number"
                    variant="outlined"
                    onChange={investPercentHandler}
                    value={investPercent}
                  />
                ) : (
                  <Button
                    sx={{ my: 1 }}
                    fullWidth
                    variant="outlined"
                    size="large"
                    style={{ fontSize: "1.5rem", color: "#00D4A1" }}
                    value="50"
                    onClick={() => setIsCostumInputActive(true)}
                  >
                    Custom
                  </Button>
                )}
              </ButtonsGroup>
              <Box mt={3}>
                <Typography fontSize="1.2rem" component="span">
                  {`You are investing `}
                </Typography>
                <Typography fontSize="1.2rem" color="primary" component="span">
                  {`${usd.format(futureBalance)} `}
                </Typography>
                <Typography fontSize="1.2rem" component="span">
                  more.
                </Typography>
              </Box>
              <Box>
                <Typography fontSize="1.2rem" component="span">
                  {`Your total investment amount is `}
                </Typography>
                <Typography fontSize="1.2rem" component="span" color="primary">
                  {onboard?.calculatorData?.investmentAmount &&
                    usd.format(
                      onboard.calculatorData.investmentAmount + futureBalance,
                    )}
                </Typography>
              </Box>
              <Box mt={4}>
                <Button
                  sx={{ margin: "auto", px: 8, minWidth: "60%" }}
                  size="large"
                  startIcon={<CoinIconWhite />}
                  variant="contained"
                  onClick={confirmHandler}
                >
                  Confirm additional investment
                </Button>
                <Button
                  sx={{ marginTop: "15px", px: 8, minWidth: "60%" }}
                  size="large"
                  variant="contained"
                  onClick={proceedWithoutInvestment}
                >
                  Proceed without additional investment
                </Button>
              </Box>
            </Investment>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default ClaimSavings;
