import styled from 'styled-components';

export const Options = styled.div`
  display: flex;
  gap: 16px;

  button {
    flex-direction: column;

    svg {
      margin-bottom: 8px;
    }
  }
`;
