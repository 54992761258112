import axios from '../axios';

export const plaid = {
  createLink: () => axios.post('/money-api/plaid/create-link-token'),
  exchangePublicToken: (data: unknown) =>
    axios.post('/money-api/plaid/exchange-public-token', data),
  getAccounts: () => axios.get('/money-api/drivewealth/get-bank-accounts'),
  unlinkBankAccount: (accountPk: string) =>
    axios.delete(`/money-api/drivewealth/unlink_bank_account/${accountPk}`),
  createFunding: (
    dwBankAccountId: string,
    amount: string,
    transactionType: 'deposit' | 'redemption'
  ) =>
    axios.post('/money-api/drivewealth/create-funding', {
      dwBankAccountId,
      amount,
      transactionType,
    }),
  withdrawFunds: ({
    dwBankAccountId,
    amount,
    liquidate,
  }: {
    dwBankAccountId: string;
    amount?: string;
    liquidate?: boolean;
  }) =>
    axios.post('/money-api/drivewealth/create-redemption', {
      dwBankAccountId,
      amount,
      liquidate,
    }),
};
