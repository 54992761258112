import styled from 'styled-components';

/* stylelint-disable property-no-vendor-prefix */
export const FinancialSitutationWrapper = styled.div`
  > form {
    max-width: 448px;
    margin: 0 auto;
    width: 100%;
    > button {
      width: 100%;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;
/* stylelint-enable property-no-vendor-prefix */
