import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  button {
    color: #000;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

export const PopoverContent = styled.div`
  padding: 25px;

  ul {
    font-size: 1.125rem;
    margin: 0;
    padding-left: 1em;

    li {
      padding: 15px;
    }
  }
`;
