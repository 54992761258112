export const RICHR_PROD_URL = 'https://richr.com';
export const CTA_REDIRECT_URL = RICHR_PROD_URL;
export const BLOG_URL = `${RICHR_PROD_URL}/blog`;
export const TERMS_URL = `${RICHR_PROD_URL}/blog/terms-of-service`;
export const PRIVACY_URL = `${RICHR_PROD_URL}/blog/privacy-policy`;
export const ABA_URL = `${RICHR_PROD_URL}/aba`;
export const CONTACT_URL = 'https://richr.drift.click/richr-money';

// Local storage keys
export const ONBOARD_STATE = 'newOnboardState';
export const APP_STATE = 'newAppState';
export const EMPLOYMENT_STATUS_KEY = 'newEmploymentStatus';
