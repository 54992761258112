import React, { useEffect, useState } from 'react';
import {
  Box,
  TableCell,
  Container,
  TableBody,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  Paper,
} from '@mui/material';
import SignedInHeader from 'components/Header/SignedInHeader';
import Footer from 'components/Footer/Footer';
import API from 'API';

function TradeHistory() {
  const [confirmations, setConfirmations] = useState<any>([]);

  useEffect(() => {
    API.User.getTradeConfirmations().then((res) => {
      res.data.forEach((statement: any) => {
        API.User.getStatementFile(statement.fileKey).then((responseData) => {
          setConfirmations(
            [...confirmations, { ...statement, ...responseData.data }] || []
          );
        });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box bgcolor="rgba(109,109,109,0.04)">
        <Container maxWidth="xl">
          <SignedInHeader />
        </Container>
      </Box>
      <Container maxWidth="xl" sx={{ mt: 11, mb: 12 }}>
        <h3>Trade Confirmations</h3>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Display Name</TableCell>
                <TableCell align="right">Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(confirmations || []).map((item: any) => (
                <TableRow
                  key={item.fileKey}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {item.displayName}
                  </TableCell>
                  <TableCell align="right">
                    <a target="_blank" href={item.url} rel="noreferrer">
                      PDF Document link
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Footer />
    </>
  );
}

export default TradeHistory;
