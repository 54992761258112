import { ReactElement } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider } from 'styled-components';
import { palette } from 'assets/styles/variables';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const theme = createTheme({
  palette: {
    ...palette,
  },
  typography: {
    allVariants: {
      fontFamily: 'Inter, Walsheim-Regular, sans-serif',
      fontWeight: 400,
      color: '#00152A',
    },
    h1: {
      fontFamily: 'Inter, Walsheim-Bold, sans-serif',
      fontWeight: 700,
      fontSize: '4.6875rem',
    },
    h2: {
      fontFamily: 'Inter, Walsheim-Bold, sans-serif',
      fontWeight: 700,
      fontSize: '4rem',
    },
    h3: {
      fontFamily: 'Inter, Walsheim-Bold, sans-serif',
      fontWeight: 700,
      fontSize: '2.875rem',
    },
    h4: {
      fontFamily: 'Inter, Walsheim-Bold, sans-serif',
      fontWeight: 700,
      fontSize: '2rem',
    },
    h5: {
      fontFamily: 'Inter, Walsheim-Bold, sans-serif',
      fontSize: '1.75rem',
    },
    h6: {
      fontFamily: 'Inter, Walsheim-Medium, sans-serif',
      fontWeight: 500,
      fontSize: '1.5rem',
    },
    body1: {
      fontSize: '1.5rem',
    },
    body2: {
      fontSize: '1.125rem',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter, Walsheim-Medium, sans-serif',
          fontSize: '1.125rem',
        },
        input: {
          '::placeholder': {
            fontFamily: 'Inter, Walsheim-Regular, sans-serif',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
          color: palette.text.secondary,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: 'Inter, Walsheim-Medium, sans-serif',
          fontSize: '1.125rem',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'Inter, Walsheim-Medium, sans-serif',
          fontWeight: 500,
        },
        contained: {
          borderRadius: 0,
          color: '#FFFFFF',
        },
        outlinedSizeLarge: {
          fontFamily: 'Inter, Walsheim-Regular, sans-serif',
          fontWeight: 400,
          color: palette.text.primary,
          borderColor: '#E8E8E8',
          borderRadius: 0,

          '&.active': {
            border: `1px solid ${palette.primary.main}`,
          },
        },
        containedSizeMedium: {
          color: palette.common.white,
        },
        sizeMedium: {
          padding: '10px 22px',
          fontFamily: 'Inter, Walsheim-Medium, sans-serif',
          fontWeight: 500,
          lineHeight: 0,
        },
        sizeLarge: {
          padding: '19px',
          fontSize: '1.125rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter, Walsheim-Medium, sans-serif',
          fontSize: '0.875rem',
          textAlign: 'center',

          '&.Mui-error::before': {
            content: '"\\e000"',
            fontFamily: 'Material Icons',
            fontSize: '20px',
            position: 'relative',
            display: 'inline',
            verticalAlign: 'middle',
            marginRight: '4px',
          },
        },
      },
    },
  },
});

type ThemeProps = {
  children: ReactElement | ReactElement[];
};

function Theme({ children }: ThemeProps) {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  );
}

export type ThemeType = typeof theme;

export default Theme;
