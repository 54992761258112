import { defineMessages } from 'react-intl';

export const scope = 'app.components.HowItWorks';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'How it works',
  },
  stepOne: {
    id: `${scope}.stepOne`,
    defaultMessage: 'Sell your home with Richr Homes for 1%',
  },
  stepTwo: {
    id: `${scope}.stepTwo`,
    defaultMessage: 'Receive your 2% cash back in Richr Money',
  },
  stepThree: {
    id: `${scope}.stepThree`,
    defaultMessage:
      'Choose your preferred fund (Serenity, Harmony, or Velocity)',
  },
  stepFour: {
    id: `${scope}.stepFour`,
    defaultMessage: 'Sit back and watch your money grow',
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: 'Sell Your Home and Invest Now with Richr Money',
  },
  imageAltText: {
    id: `${scope}.imageAltText`,
    defaultMessage: 'how it works',
  },
});
