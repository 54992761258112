import { ChangeEvent, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import API from "API";
import DatePicker from "react-datepicker";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { OnboardState } from "../../../store/OnboardState/OnboardState";
import { AppState } from "../../../store/app/AppState";
import { InputBox } from "./styles";
import "react-datepicker/dist/react-datepicker.css";

type FormType = {
  firstName: string;
  lastName: string;
  dob: Date;
  ssn: string;
};

function Info() {
  const { updateOnboard } = OnboardState.useContainer();
  const {
    appState: { user },
  } = AppState.useContainer();

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormType>();

  const navigate = useNavigate();
  const [SSN, setSSN] = useState("");

  const onSubmit: SubmitHandler<FormType> = (data) => {
    API.User.userDetails({
      ...data,
      dob: dayjs(data.dob).format("YYYY-MM-DD"),
      ssn: data.ssn.replace(/-/g, ""),
    })
      .then(() => {
        localStorage.setItem("SSN", SSN.replaceAll("-", ""));
        updateOnboard({ step: 1 });
        navigate("/onboard/financial-situation");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/onboard/sorry");
        }
      });
  };

  const handleSSNChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = e.target;
    const regex = /^[\d-]+$/;
    // only numbers and dashes are allowed
    if (regex.test(value) || value === "") {
      if (
        (value.length === 3 || value.length === 6) &&
        value.length > SSN.length
      ) {
        setSSN(`${value}-`);
      } else {
        setSSN(value);
      }
    }
  };

  useEffect(() => {
    if (user.id) {
      const birth = new Date(user.dob);
      setValue("lastName", user.lastName);
      setValue("firstName", user.firstName);
      setValue("dob", birth);
    }
  }, [user, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Logo />
      </div>

      <Box my={2}>
        <Typography
          variant="h4"
          fontFamily="Walsheim-Light"
          display="inline-block"
        >
          Welcome to&nbsp;
        </Typography>
        <Typography variant="h4" display="inline-block">
          Richr Money
        </Typography>
      </Box>
      <Typography variant="body2" my={3}>
        The brokerage team has sent us the details to create your Richr Money
        account. Please confirm the following information is correct. If you see
        an error, please email money@richr.com.
      </Typography>

      <TextField
        autoComplete="given-name"
        fullWidth
        // label="First name"
        margin="normal"
        {...register("firstName", {
          required: "Please enter your first name",
        })}
        error={Boolean(errors.firstName)}
        helperText={errors.firstName?.message}
      />
      <TextField
        autoComplete="family-name"
        fullWidth
        // label="Last name"
        margin="normal"
        {...register("lastName", {
          required: "Please enter your last name",
        })}
        error={Boolean(errors.lastName)}
        helperText={errors.lastName?.message}
      />
      <Controller
        name="dob"
        control={control}
        rules={{
          required: "Please enter your date of birth",
          validate: {
            test: (value) =>
              value && dayjs(value).isValid()
                ? true
                : "Please enter your date of birth in MM-DD-YYYY format",
            age: (value) => {
              const selected = new Date(value);
              const now = new Date();
              let age = now.getFullYear() - selected.getFullYear();
              const m = now.getMonth() - selected.getMonth();
              if (m < 0 || (m === 0 && now.getDate() < selected.getDate())) {
                age -= 1;
              }
              if (!selected) {
                return "Please enter your date of birth in MM-DD-YYYY format";
              }
              if (age < 18) {
                return "You must be 18 to create an account";
              }
              return true;
            },
          },
        }}
        render={({ field }) => (
          <InputBox
            className={`${
              errors.dob ? "has-error" : ""
            } MuiFormControl-root MuiFormControl-marginNormal MuiFormControl-fullWidth MuiTextField-root css-17vbkzs-MuiFormControl-root-MuiTextField-root`}
          >
            <DatePicker
              className="MuiOutlinedInput-input MuiInputBase-input css-1skfydl-MuiInputBase-input-MuiOutlinedInput-input"
              placeholderText="Date of birth"
              onChange={(date) => field.onChange(date)}
              selected={field.value}
            />
            {errors.dob?.message && (
              <FormHelperText className="Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained">
                {errors.dob?.message}
              </FormHelperText>
            )}
          </InputBox>
        )}
      />
      {/* <TextField
        disabled
        autoComplete="email"
        type="email"
        fullWidth
        value={user.}
        margin="normal"
      /> */}
      <TextField
        fullWidth
        label="SSN"
        margin="normal"
        {...register("ssn", {
          required: "Please enter your social security number",
          pattern: {
            value: /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/,
            message: "Please enter valid social security number",
          },
          onChange: handleSSNChange,
        })}
        value={SSN}
        inputProps={{ maxLength: 11 }}
        error={Boolean(errors.ssn)}
        helperText={errors.ssn?.message}
      />
      <Button
        variant="contained"
        type="submit"
        size="large"
        fullWidth
        sx={{ mt: 3, mb: 2 }}
      >
        Confirm and Continue
      </Button>
    </form>
  );
}

export default Info;
