import styled from 'styled-components';

export const LearnMoreSectionImage = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 720px;
    height: 700px;
  }

  @media (max-width: 1150px) {
    img {
      width: 553px;
      height: 538px;
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    img {
      width: 100%;
      height: auto;
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;

    img {
      height: 275px;
      object-fit: cover;
    }
  }
`;

export const LearnMoreSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
    align-items: center;

    .learn-more-section {
      padding: 40px 20px;
    }
  }
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  ${(props) => props.theme.breakpoints.down('md')} {
    align-items: center;

    a,
    p {
      text-align: center;
    }

    .learn-more-button {
      padding: 10px 25px;
    }
  }
`;
