/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import API from 'API';
import { createContainer } from 'unstated-next';
import { clearOnboard } from 'store/OnboardState/OnboardState';
import { APP_STATE } from 'constant';

function useAppState(
  initialState = {
    accessToken: '',
    user: {
      acceptedInvite: false,
      amount: '',
      selectedAmount: null,
      dob: '',
      firstName: '',
      id: null,
      investmentBucket: {
        title: '',
        description: '',
      },
      lastName: '',
      onboardingCompleted: false,
      userName: '',
      dwUserId: null,
    },
    accountData: {
      accountNo: '',
      userID: '',
      accountType: {
        name: '',
        description: '',
      },
      commissionID: '',
      beneficiaries: true,
      freeTradeBalance: 0,
      goodFaithViolations: 0,
      parentIB: {
        id: '',
        name: '',
      },
      leverage: 0,
      openedWhen: '',
      patternDayTrades: 0,
      status: {
        name: '',
        description: '',
      },
      sweepInd: null,
      tradingType: {
        name: '',
        description: '',
      },
      createdWhen: '',
      bod: {
        moneyMarket: 0,
        equityValue: 0,
        cashAvailableForWithdrawal: 0,
        cashAvailableForTrading: 0,
        updatedWhen: '',
        cashBalance: 0,
      },
      restricted: false,
      nickname: '',
      interestFree: false,
      accountMgmtType: {
        name: '',
        description: '',
      },
      ria: {},
      id: '',
    },
    accountSummary: {
      accountID: '',
      accountNo: '',
      tradingType: '',
      lastUpdated: '',
      cash: {
        cashAvailableForTrade: 0,
        cashAvailableForWithdrawal: 0,
        cashBalance: 0,
        noBuyingPowerReason: null,
        cashSettlement: [],
        pendingPaymentsAmount: 0,
      },
      margin: null,
      violations: {
        goodFaithViolations: {
          count: 0,
        },
        patternDayTrades: null,
        details: null,
      },
      equity: {
        equityValue: 0,
        equityPositions: [],
      },
      orders: [],
      transactions: [],
    },
  },
) {
  const [appState, setAppState] = useState(initialState);
  useEffect(() => {
    // retrieves info from a localStorage and initializes to the state.
    const retrievedAppState = JSON.parse(
      localStorage.getItem(APP_STATE) || '{}',
    );
    // get user info and store it in the state
    if (retrievedAppState?.accessToken) {
      API.User.getUserInfo().then(({ data }) => {
        retrievedAppState.user = data;
        setAppState(retrievedAppState);
        localStorage.setItem(APP_STATE, JSON.stringify(retrievedAppState));
      });
      if (JSON.parse(localStorage.getItem(APP_STATE) || '{}').user?.dwUserId) {
        API.User.getAccount().then(({ data }) => {
          retrievedAppState.accountData = data.account;
          setAppState(retrievedAppState);
          localStorage.setItem(APP_STATE, JSON.stringify(retrievedAppState));
        });
        API.User.getAccountSummary().then(({ data }) => {
          retrievedAppState.accountSummary = data.accountSummary;
          setAppState(retrievedAppState);
          localStorage.setItem(APP_STATE, JSON.stringify(retrievedAppState));
        });
      }
    }
  }, []);

  const logOut = () => {
    localStorage.removeItem(APP_STATE);
    setAppState(initialState);
    clearOnboard();
  };

  const setAccessToken = (userData: any) => {
    if (userData.key) {
      userData.accessToken = userData.key;
    }
    if (userData.moneyUser) {
      userData.user = userData.moneyUser;
    }

    const updatedUserInfo = {
      ...JSON.parse(
        localStorage.getItem(APP_STATE) || JSON.stringify(initialState),
      ),
      ...userData,
    };

    localStorage.setItem(APP_STATE, JSON.stringify(updatedUserInfo));
    setAppState(updatedUserInfo);
  };

  const setOnboardComplete = () => {
    const updatedUserInfo = {
      ...JSON.parse(
        localStorage.getItem(APP_STATE) || JSON.stringify(initialState),
      ),
      user: {
        ...JSON.parse(
          localStorage.getItem(APP_STATE) || JSON.stringify(initialState),
        ).user,
        onboardingCompleted: true,
      },
    };

    localStorage.setItem(APP_STATE, JSON.stringify(updatedUserInfo));
    setAppState(updatedUserInfo);
  };

  return { appState, setAccessToken, logOut, setOnboardComplete };
}

export const AppState = createContainer(useAppState);
