import React, { useEffect } from 'react';
import axios from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';
// import API from 'API';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { SignupFullForm } from 'store/signup/SignupFullForm';
import PasswordTextField from '../../../components/PasswordTextField/PasswordTextField';
import PasswordRequirements from '../../../components/PasswordRequirments/PasswordRequirements';

type FormInputType = {
  username: string;
  password: string;
};

function SignupForm() {
  const { signupFullForm, updateSignupFullForm } =
    SignupFullForm.useContainer();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormInputType>();
  const onSubmit: SubmitHandler<FormInputType> = (data) => {
    updateSignupFullForm(data);
    navigate('/signup/confirm-identity');
  };
  const { inviteCode } = useParams();
  // const [userInfo, setUserInfo] = useState({ email: '' });

  useEffect(() => {
    if (!signupFullForm.inviteCode) {
      updateSignupFullForm({
        inviteCode: inviteCode || '',
      });
    }

    // API.User.checkInviteCode({ inviteCode })
    //   .then(({ data }) => setUserInfo(data))
    //   .catch(() => navigate('/'));
  }, [inviteCode, navigate, signupFullForm, updateSignupFullForm]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Logo />
      </div>
      <Box my={2}>
        <Typography
          variant="h4"
          fontFamily="Walsheim-Light"
          display="inline-block"
        >
          Welcome to&nbsp;
        </Typography>
        <Typography variant="h4" display="inline-block">
          Richr Money
        </Typography>
      </Box>
      <Box width={['100%', '70%']} margin="auto">
        <Typography variant="body2" my={3}>
          The brokerage team has sent us the details to create your Richr Money
          account. Please create a password.
        </Typography>
        {/* <TextField
          disabled
          autoComplete="email"
          type="email"
          fullWidth
          value={userInfo.email}
          margin="normal"
        /> */}
        <TextField
          autoComplete="username"
          fullWidth
          label="Username"
          margin="normal"
          {...register('username', {
            validate: {
              unique: async (value) => {
                try {
                  return await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/money-api/auth/check-username-unique`,
                    {
                      identifier: value,
                    }
                  );
                } catch (e) {
                  return 'Please enter username';
                }
              },
            },
          })}
          error={Boolean(errors.username)}
          helperText={errors.username?.message}
        />
        <PasswordTextField
          autoComplete="new-password"
          fullWidth
          label="Password"
          margin="normal"
          {...register('password', {
            required: 'Please enter a password',
            minLength: {
              value: 8,
              message: 'Please enter a password with at least 8 characters',
            },
            pattern: {
              value:
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
              message:
                'Please follow the instructions of password requirements',
            },
          })}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
        />
        <PasswordRequirements />
        <Button
          variant="contained"
          type="submit"
          size="large"
          fullWidth
          sx={{ mt: 3 }}
        >
          Confirm and Continue
        </Button>
      </Box>
    </form>
  );
}

export default SignupForm;
