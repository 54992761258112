import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { sortBy, isNil } from 'lodash';

import { OnboardState } from 'store/OnboardState/OnboardState';
import API from 'API';
import { getBucketLevelInterestRate } from 'utils/helpers/getBucketLevel';
import { ReactComponent as RiskImage } from '../assets/risk.svg';
import Question1 from './Question1/Question1';
import Question2 from './Question2/Question2';
import Question3 from './Question3/Question3';

export type QuestionProps = {
  riskToleranceAnswers: { choice: { id: number } }[];
  onChoicePick: (choiceId: number) => void;
};

function RiskProfile() {
  const { question } = useParams();
  const { incrementStep, setCalculatorData } = OnboardState.useContainer();
  const navigate = useNavigate();

  const [riskToleranceQuestions, setRiskToleranceQuestions] = useState([
    {
      id: 0,
      text: '',
      choices: [],
    },
  ]);

  const [riskToleranceAnswers, setRiskToleranceAnswers] = useState(
    JSON.parse(localStorage.getItem('riskToleranceAnswers') || '[]'),
  );

  const handleChoicePick = useCallback(
    (choiceId: number) => {
      const questionId = question ? Number(question) - 1 : 0;

      const answer = {
        id: riskToleranceQuestions[questionId].id,
        text: riskToleranceQuestions[questionId].text,
        choice: riskToleranceQuestions[questionId].choices.find(
          ({ id }) => id === choiceId,
        ),
      };
      const updatedAnswers = [...riskToleranceAnswers];
      updatedAnswers[questionId] = answer;
      setRiskToleranceAnswers(updatedAnswers);
    },
    [question, riskToleranceAnswers, riskToleranceQuestions],
  );

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (question !== '3') {
        localStorage.setItem(
          'riskToleranceAnswers',
          JSON.stringify(riskToleranceAnswers),
        );
        incrementStep();
        navigate(
          `/onboard/risk-profile/${question ? Number(question) + 1 : ''}`,
        );
      } else {
        API.Onboarding.calculateRiskTolerance(riskToleranceAnswers)
          .then((result: any) => {
            setCalculatorData({
              bucketInformation: result.data.data,
              interestRate: getBucketLevelInterestRate(
                result.data.data.bucketLevel,
              ),
              investmentOption: [
                result.data.data.title,
                result.data.data.description,
              ],
            });
            localStorage.removeItem('riskToleranceAnswers');
            incrementStep();
            navigate(`/onboard/agree-terms`);
          })
          .catch(() => navigate('/onboard/sorry'));
      }
    },
    [
      incrementStep,
      question,
      navigate,
      riskToleranceAnswers,
      setCalculatorData,
    ],
  );

  useEffect(() => {
    (async () => {
      const { data } = await API.Onboarding.getRiskToleranceQuestions();

      if (!isNil(data)) {
        const sortedData = sortBy(data, ['id', 'asc']);

        setRiskToleranceQuestions(sortedData);
      }
    })();
  }, []);

  let content;
  switch (question) {
    case '2':
      content = (
        <Question2
          riskToleranceAnswers={riskToleranceAnswers}
          onChoicePick={handleChoicePick}
        />
      );
      break;
    case '3':
      content = (
        <Question3
          riskToleranceAnswers={riskToleranceAnswers}
          onChoicePick={handleChoicePick}
        />
      );
      break;
    default:
      content = (
        <Question1
          riskToleranceAnswers={riskToleranceAnswers}
          onChoicePick={handleChoicePick}
        />
      );
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <RiskImage />
      </div>
      <Box my={2}>
        {/* <Typography
          variant="h4"
          fontFamily="Walsheim-Light"
          display="inline-block"
        >
          Let’s Talk About&nbsp;
        </Typography> */}
        <Typography variant="h4" display="inline-block">
          Three More Questions…
        </Typography>
      </Box>
      <Typography variant="body2" my={3}>
        These questions help us advise you on the right investments to meet your
        financial goals.
      </Typography>
      {content}
      <Button
        disabled={riskToleranceAnswers.length === 0}
        variant="contained"
        type="submit"
        size="large"
        fullWidth
        sx={{ mt: 3 }}
      >
        Continue
      </Button>
    </form>
  );
}

export default RiskProfile;
