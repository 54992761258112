import { Button, Divider, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as Logo } from 'assets/icons/logo-horizontal.svg';
import { SectionLayout } from 'components/layouts/sectionLayout';
import {
  ABA_URL,
  BLOG_URL,
  CONTACT_URL,
  CTA_REDIRECT_URL,
  PRIVACY_URL,
  TERMS_URL,
} from 'constant';
import messages from './messages';
import {
  Summary,
  ReadyToStart,
  FooterDescription,
  ItemsContainer,
  BottomSectionWrapper,
  Info,
  Copyright,
  TermsAndPrivacy,
  Legal,
} from './styles';

function HomepageFooter() {
  const intl = useIntl();

  function renderBottomSection() {
    return (
      <BottomSectionWrapper>
        <Info>
          <Copyright>
            <Logo />
            <Typography variant="body1">
              <FormattedMessage {...messages.copyright} />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage {...messages.allRightsReserved} />
            </Typography>
          </Copyright>
          <TermsAndPrivacy>
            <Typography variant="body1">
              <FormattedMessage {...messages.termsAndPrivacy} />
            </Typography>
            <Typography variant="body1" component="a">
              <a href={TERMS_URL}>
                <FormattedMessage {...messages.linkOne} />
              </a>
            </Typography>
            <Typography variant="body1" component="a">
              <a href={PRIVACY_URL}>
                <FormattedMessage {...messages.linkTwo} />
              </a>
            </Typography>
            <Typography variant="body1" component="a">
              <a href={ABA_URL}>
                <FormattedMessage {...messages.linkThree} />
              </a>
            </Typography>
          </TermsAndPrivacy>
        </Info>
        <Legal>
          <Typography variant="body1">
            <FormattedMessage
              {...messages.legal}
              values={{ apos: <>&apos;</> }}
            />
          </Typography>
        </Legal>
      </BottomSectionWrapper>
    );
  }

  return (
    <ReadyToStart>
      <SectionLayout className="ready-to-start-section">
        <ItemsContainer>
          <Summary>
            <Typography variant="h1">
              <FormattedMessage {...messages.title} />
            </Typography>
            <Typography variant="body1" className="footer-description">
              <FormattedMessage
                {...messages.description}
                values={{
                  rsquo: <>&rsquo;</>,
                  richr_link: (
                    <a href={CTA_REDIRECT_URL} className="description-link">
                      {intl.formatMessage({ ...messages.richrHomepage })}
                    </a>
                  ),
                }}
              />
            </Typography>
            <Button
              variant="contained"
              disableElevation
              href={CTA_REDIRECT_URL}
            >
              <FormattedMessage {...messages.button} />
            </Button>
          </Summary>
          <Divider variant="middle" orientation="vertical" flexItem />
          <FooterDescription>
            <Typography variant="body1" component="h6">
              <FormattedMessage
                {...messages.details}
                values={{
                  blogLink: (
                    <a href={BLOG_URL} className="description-link">
                      {intl.formatMessage({ ...messages.blogLinkText })}
                    </a>
                  ),
                }}
              />
            </Typography>
            <br />
            <Typography variant="body1" component="h6">
              <FormattedMessage
                {...messages.contactUs}
                values={{
                  rsquo: <>&rsquo;</>,
                  contactLink: (
                    <a href={CONTACT_URL} className="description-link">
                      {intl.formatMessage({ ...messages.contactUsLinkText })}
                    </a>
                  ),
                }}
              />
            </Typography>
          </FooterDescription>
        </ItemsContainer>
        <Divider variant="middle" orientation="vertical" flexItem />
        {renderBottomSection()}
      </SectionLayout>
    </ReadyToStart>
  );
}

export default HomepageFooter;
