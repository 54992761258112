import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { Foot } from './styles';

function Footer() {
  return (
    <Foot>
      <div>
        <Container maxWidth="lg">
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Terms & Privacy</Typography>
              <Typography variant="body2" mt={1}>
                Terms of Service
              </Typography>
              <Typography variant="body2" mt={1}>
                Privacy Policy
              </Typography>
              <Typography variant="body2" mt={1}>
                Affiliated Business Disclosure
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" justifyContent="right">
                <Logo overflow="visible" />
                <Typography variant="body2" ml={2}>
                  2627 NE 203rd St, Suite 202,
                  <br />
                  Miami, FL 33180
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div>
        <Typography fontWeight={700}>
          © Copyright 2022. Richr Inc. ALL RIGHTS RESERVED.
        </Typography>
        <Typography mt={2}>
          All information provided is deemed reliable but is not guaranteed and
          should be independently verified. You should consult your own
          professionals for financial, legal, or tax advice and conduct your own
          due diligence. Brokerage services are provided by Richr Homes, FL
          License # 1060165. Services of Richr&apos;s affiliates, Richr
          Insurance and Richr Title, are optional and can be used in combination
          or separately.
        </Typography>
      </div>
    </Foot>
  );
}

export default Footer;
