import React from 'react';
import { Button, Typography } from '@mui/material';
import { QuestionProps } from '../RiskProfile';

function Question1({ riskToleranceAnswers, onChoicePick }: QuestionProps) {
  return (
    <>
      <Typography variant="h6" mt={4} mb={1} fontFamily="Walsheim-Medium">
        What is your financial goal for these funds?
      </Typography>
      <Button
        sx={{ my: 1 }}
        className={riskToleranceAnswers[0]?.choice.id === 1 ? 'active' : ''}
        fullWidth
        variant="outlined"
        size="large"
        onClick={() => onChoicePick(1)}
      >
        My goal is high growth and I accept substantial risk of market
        fluctuations.
      </Button>
      <Button
        sx={{ my: 1 }}
        className={riskToleranceAnswers[0]?.choice.id === 2 ? 'active' : ''}
        fullWidth
        variant="outlined"
        size="large"
        onClick={() => onChoicePick(2)}
      >
        My goal is moderate growth and I can tolerate market fluctuations.
      </Button>
      <Button
        sx={{ my: 1 }}
        className={riskToleranceAnswers[0]?.choice.id === 3 ? 'active' : ''}
        fullWidth
        variant="outlined"
        size="large"
        onClick={() => onChoicePick(3)}
      >
        My goal is conservative growth and I want minimal exposure to market
        fluctuations.
      </Button>
    </>
  );
}

export default Question1;
