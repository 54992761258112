import { createGlobalStyle } from 'styled-components';
import { breakpoints, palette } from 'assets/styles/variables';
import { boldText } from './components/fonts';

export default createGlobalStyle`
  html {
    @media screen and (max-width: ${breakpoints.xl}){
      font-size: 14px;
    }
  }

  body {
    margin: 0;
    font-family: 'Inter', 'Walsheim-Regular', sans-serif;
    font-weight: 400;
  }
  
  a {
    color: ${palette.primary.main};
  }

  h1,
  h2,
  h3{
    font-family: Walsheim-Bold, sans-serif;
    color: #00152A;
  }
  
  h1 {
    font-size: 4.6875rem;
  }
  h2 {
    font-size: 4rem;
  }
  h3 {
    font-size: 2.875rem;
  }

  h4 {
    font-size: 2rem;
  }

  b {
    ${boldText}
  }
  
`;
