/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { ONBOARD_STATE } from 'constant';

// eslint-disable-next-line no-var, import/no-mutable-exports
var clearOnboard: any = null;

function useOnboardState(
  initialState = {
    step: 0,
    calculatorData: {
      investmentAmount: undefined,
      investmentPeriod: 30,
      interestRate: 4,
      numberOfTimesInMonth: 12,
      futureBalance: 0,
      homeSalePrice: 0,
      investmentOption: ["Conservative", "2-4% return"],
      bucketInformation: {
        bucketLevel: "",
        descripton: "",
        id: null,
        order: null,
        shortName: "",
        title: "",
      },
    },
  },
) {
  const [onboard, setOnboard] = useState(
    JSON.parse(localStorage.getItem(ONBOARD_STATE) || "{}") || initialState,
  );

  // retrieves info from a localStorage and initializes OnboardState state.
  useEffect(() => {
    const retrievedOnboarding = JSON.parse(
      localStorage.getItem(ONBOARD_STATE) || "{}",
    );
    setOnboard(retrievedOnboarding);
  }, []);

  clearOnboard = () => {
    localStorage.removeItem(ONBOARD_STATE);
    setOnboard(initialState);
  };

  const updateOnboard = (data: { [key: string]: string | number }) => {
    const store =
      JSON.parse(localStorage.getItem(ONBOARD_STATE) || "{}") || onboard;
    const updatedOnboard = {
      ...store,
      ...data,
    };
    localStorage.setItem(ONBOARD_STATE, JSON.stringify(updatedOnboard));
    setOnboard(updatedOnboard);
  };

  const incrementStep = () => {
    const store =
      JSON.parse(localStorage.getItem(ONBOARD_STATE) || "{}") || onboard;
    const updatedOnboard = {
      ...store,
      step: onboard.step + 1,
    };
    localStorage.setItem(ONBOARD_STATE, JSON.stringify(updatedOnboard));
    setOnboard(updatedOnboard);
  };
  const setCalculatorData = (data: {
    investmentAmount?: number;
    investmentPeriod?: number;
    interestRate?: number;
    numberOfTimesInMonth?: number;
    futureBalance?: number;
    homeSalePrice?: number;
    investmentOption?: string[];
    bucketInformation?: {
      bucketLevel: string;
      descripton: string;
      id: number;
      order: number;
      shortName: string;
      title: string;
    };
  }) => {
    const store: typeof initialState = JSON.parse(
      localStorage.getItem(ONBOARD_STATE) || JSON.stringify(initialState),
    );
    const updatedData = {
      ...onboard,
      ...store,
      calculatorData: {
        ...onboard.calculatorData,
        ...store.calculatorData,
        ...data,
      },
    };
    localStorage.setItem(ONBOARD_STATE, JSON.stringify(updatedData));
    setOnboard(updatedData);
  };

  return {
    onboard,
    updateOnboard,
    incrementStep,
    setCalculatorData,
    clearOnboard,
  };
}
export { clearOnboard };
export const OnboardState = createContainer(useOnboardState);
