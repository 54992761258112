import styled from 'styled-components';
import { ReactComponent as CoinIcon } from 'assets/icons/coin.svg';

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 240px;

  > div {
    width: 100%;
  }
`;

export const AmountInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding-top: 36px;
  & > div {
    display: flex;
    justify-content: space-between;
  }
  p {
    margin-bottom: 18px;
  }
`;

export const InfoBox = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 24px;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.09);
`;

export const GriffWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 48px 0;
  justify-content: center;
`;

export const Investment = styled.form`
  text-align: center;
  /* stylelint-disable */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const CoinIconWhite = styled(CoinIcon)`
  g {
    fill: white;
  }
`;

export const LocationImage = styled.img`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 32px;
`;

export const PriceUnderlineImg = styled.img`
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  z-index: -1;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90%;

  & > * {
    width: 96px;
    height: 96px;
    font-size: 2rem;
    margin-right: 16px;
  }

  .css-1p3h52h-MuiInputBase-root-MuiOutlinedInput-root {
    width: 96px;
    font-size: 2rem;
    height: 96px;
  }

  .css-14srvij-MuiTypography-root {
    font-size: 2rem;
  }

  .css-1lluu7o-MuiFormLabel-root-MuiInputLabel-root {
    top: 21px !important;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    margin: 0;
  }
`;
