import React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import API from 'API';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { OnboardState } from 'store/OnboardState/OnboardState';
import { AppState } from 'store/app/AppState';
import { FinancialSitutationWrapper } from './styled';

type FormInputType = {
  total: string;
  annual: string;
};

function FinancialSitutation() {
  const { incrementStep } = OnboardState.useContainer();
  const {
    appState: { user },
  } = AppState.useContainer();

  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormInputType>({ reValidateMode: 'onChange' });
  const onSubmit: SubmitHandler<FormInputType> = async (values) => {
    await API.Onboarding.addFinancialSituation({
      annual: values.annual,
      total: values.total,
      firstName: user.firstName,
      lastName: user.lastName,
      dob: user.dob,
      ssn: localStorage.getItem('SSN') ?? '',
    });
    incrementStep();
    navigate('/onboard/agree-terms');
  };

  return (
    <FinancialSitutationWrapper>
      <Logo />
      <Typography variant="h4" mt={2} mb={1} fontFamily="Walsheim-Bold">
        Your Financial Situation
      </Typography>
      <Typography variant="h5" mt={2} mb={4} fontFamily="Walsheim-Regular">
        We kindly ask you to provide approximate numbers.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          label="TOTAL NET-WORTH ($)"
          margin="normal"
          {...register('total', {
            required: true,
            pattern: {
              value:
                /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/,
              message: 'Please Enter a valid number',
            },
          })}
          error={Boolean(errors.total)}
          helperText={errors.total?.message}
          type="number"
        />
        <TextField
          fullWidth
          label="ANNUAL INCOME ($)"
          margin="normal"
          {...register('annual', {
            required: true,
            pattern: {
              value:
                /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/,
              message: 'Please Enter a valid number',
            },
          })}
          error={Boolean(errors.annual)}
          helperText={errors.annual?.message}
          type="number"
        />
        <Button
          variant="contained"
          type="submit"
          size="large"
          sx={{ mt: 3, width: ['100%', '70%'] }}
        >
          Confirm and Continue
        </Button>
      </form>
    </FinancialSitutationWrapper>
  );
}

export default FinancialSitutation;
