import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { initializeSentry } from 'sentry';
import { ReactNotifications } from 'react-notifications-component';

import './assets/styles/fonts.css';
import { AppState } from './store/app/AppState';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Theme from './assets/styles/Theme';
import GlobalStyles from './assets/styles/GlobalStyles';
import 'react-notifications-component/dist/theme.css';

initializeSentry();

ReactDOM.render(
  <React.StrictMode>
    <Theme>
      <GlobalStyles />
      <ReactNotifications />
      <BrowserRouter>
        <IntlProvider locale="en" defaultLocale="en">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AppState.Provider>
              <App />
            </AppState.Provider>
          </LocalizationProvider>
        </IntlProvider>
      </BrowserRouter>
    </Theme>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
