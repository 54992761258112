import { palette } from 'assets/styles/variables';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ButtonWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;

  g {
    fill: white;
  }
`;

export const LinkPrimary = styled(Link)`
  font-family: Walsheim-Bold, sans-serif;
  color: ${palette.primary.main};
`;
