import React, { useState } from 'react';
import { Button, Popover, Typography } from '@mui/material';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { Wrapper, PopoverContent } from './styles';

function PasswordRequirements() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'password-popover' : undefined;

  return (
    <Wrapper>
      <Button startIcon={<KeyIcon />} onClick={handleClick}>
        Strong Password Requirements
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <PopoverContent>
          <Typography variant="h6">Password Requirements</Typography>
          <ul>
            <li>At least 8 characters—the more characters, the better</li>
            <li>A mixture of both uppercase and lowercase letters</li>
            <li>A mixture of letters and numbers</li>
            <li>
              Inclusion of at least one special character, e.g., ! @ # ? ]
            </li>
          </ul>
        </PopoverContent>
      </Popover>
    </Wrapper>
  );
}

export default PasswordRequirements;
